import React, { useCallback, useEffect, useState } from 'react';
import { DialogComponent, Tables, ViewTypes } from '../../../../Components';
import { ViewTypesEnum } from '../../../../Enums';
import { GetConvoloCallsAnalysis } from '../../../../Services';
import { showError } from '../../../../Helper';
import AgentCallsTableView from './components/AgentCallsTableView';
import AgentCallCardView from './components/AgentCallCardView';
import { useLocalStorage } from '../../../../Hooks';

function AgentCallsDialog({
  parentTranslationPath,
  translationPath,
  open,
  onClose,
  agentId,
}) {
  const [data, setData] = useState({ totalCount: 0, result: [] });
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useLocalStorage('Pagination');

  const [filter, setFilter] = useState({
    pageIndex: 1,
    pageSize: +pagination || 25,
    agentId,
  });
  const [viewType, setViewType] = useState(ViewTypesEnum.tableView.key);

  const getConvoloCallsAnalysis = useCallback(async () => {
    try {
      setLoading(true);
      const response = await GetConvoloCallsAnalysis(filter);
      setData(response);
    } catch (error) {
      showError('something-went-wrong');
    } finally {
      setLoading(false);
    }
  }, [filter]);

  useEffect(() => {
    getConvoloCallsAnalysis();
  }, [getConvoloCallsAnalysis]);

  useEffect(() => {
    setPagination(filter.pageSize);
  }, [filter.pageSize]);

  return (
    <DialogComponent
      titleText={'agent-calls'}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      isOpen={open}
      onCloseClicked={onClose}
      dialogContent={
        <>
          <div className='d-flex fj-end mb-3'>
            <ViewTypes
              activeTypes={[
                ViewTypesEnum.cards.key,
                ViewTypesEnum.tableView.key,
              ]}
              initialActiveType={ViewTypesEnum.tableView.key}
              onTypeChanged={setViewType}
            />
          </div>
          {viewType === ViewTypesEnum.tableView.key && (
            <AgentCallsTableView
              data={data}
              loading={loading}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              filter={filter}
              setFilter={setFilter}
            />
          )}
          {viewType === ViewTypesEnum.cards.key && (
            <AgentCallCardView
              data={data}
              loading={loading}
              filter={filter}
              setFilter={setFilter}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          )}
        </>
      }
    />
  );
}

export default AgentCallsDialog;
