import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  filtersContainer: {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
  },
  quickFilters: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "24px",
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
      gap: "12px",
    },
  },
  fieldWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    flexWrap: "wrap",
    gap: "12px",
    
    [theme.breakpoints.down('md')]: {
      justifyContent: "center",
      
      width: "100%",
    },

    [theme.breakpoints.down('426')]: {
      width: "100%",
      justifyContent: "flex-start",
      '& .MuiBox-root': {
        width: "100%",
      }
    },
  },
  customInputContainer: {
    width: "400px !important",
    [theme.breakpoints.down('426')]: {
      width: "100% !important",
    },
  },
  selectFields: {
    display: "flex",
    gap: "12px",
    [theme.breakpoints.down('426')]: {
      width: "100%",
      '& .MuiBox-root': {
        width: "50%",
      }
    },
  },
  customSelect: {
    [theme.breakpoints.up('426')]: {
      width: "191px !important",
    },
    [theme.breakpoints.down('426')]: {
      width: "100% !important",
    },
  }
}));
