import React from 'react'

export const NationalityConfiguration = () => {
  return (
    <div>
      NationalityConfiguration
    </div>
  )
}

