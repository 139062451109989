import React, { useEffect, useState } from 'react';
import { GetAllShareDocumentLog } from '../../../Services';
import { Tables } from '../../../Components';
import ShareDocumentLogHeader from './sharedDocumentLogHeaderData';
import './SharedDocumentLog.scss';

const parentTranslationPath = 'SharedDocumentLogView';
const translationPath = '';
export const SharedDocumentLogView = () => {
  const [sharedDocumentData, setSharedDocumentData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem("Pagination")) ||25,
    orderBy: 1,
    filterBy : null
  });
  const getAllShareDocumentLog = async () => {
    const res = await GetAllShareDocumentLog({
      ...filter,
      pageIndex: filter.pageIndex + 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setSharedDocumentData(res);
    }
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({
      ...item,
      pageIndex: 0,
      pageSize,
    }));
  };

  useEffect(() => {
    getAllShareDocumentLog();
  }, [filter]);
  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({ ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy }));
  }, [sortBy]);

  return (
    <div className='mt-4'>
      <Tables
        data={sharedDocumentData.result || []}
        headerData={ShareDocumentLogHeader || []}
        defaultActions={[]}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex}
        totalItems={sharedDocumentData.totalCount}
        setSortBy={setSortBy}
      />
    </div>
  );
};
