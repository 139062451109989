import React, { useState } from 'react';
import { Tables } from '../../../../../Components';
import { CopyToClipboardComponents } from '../../../../../ReusableComponents/UtilityComponents';
import Recommendation from './Recommendation';
import { TableActions } from '../../../../../Enums';
import MoreDetails from './MoreDetails';

function AgentCallsTableView({
  data,
  loading,
  parentTranslationPath,
  translationPath,
  filter,
  setFilter,
}) {
  const [activeItem, setActiveItem] = useState(null);
  const onActionClicked = (actionId, item) => {
    if (actionId === TableActions.view.key) {
      setActiveItem(item);
    }
  };
  return (
    <>
      <Tables
        data={data.result}
        totalItems={data.totalCount}
        isLoading={loading}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        headerData={[
          // {
          //   label: 'activity',
          //   input: 'activity',
          // },
          {
            label: 'client-name',
            input: 'lead',
          },
          {
            label: 'lead-id',
            input: 'lead-id',
            component: (row) => (
              <CopyToClipboardComponents
                data={row.leadId}
                childrenData={row.leadId}
              />
            ),
          },
          {
            label: 'agent',
            input: 'agent',
          },
          {
            label: 'call-duration',
            input: 'callDuration',
          },
          {
            label: 'recommendation',
            input: 'adviceForAgent',
            component: (row) => (
              <Recommendation recommendation={row.adviceForAgent} />
            ),
          },
          {
            label: 'date',
            input: 'createdOn',
            isDate: true,
          },
        ]}
        isOriginalPagination
        activePage={filter.pageIndex - 1}
        itemsPerPage={filter.pageSize}
        activePageChanged={(_e, newValue) =>
          setFilter((prevFilter) => ({
            ...prevFilter,
            pageIndex: newValue + 1,
          }))
        }
        actionsOptions={{ onActionClicked }}
        bodyRowId={'agent-calls'}
        itemsPerPageChanged={(e) =>
          setFilter((prevFilter) => ({
            ...prevFilter,
            pageSize: +e.target.value,
          }))
        }
        defaultActions={[{ enum: TableActions.view.key }]}
      />
      {!!activeItem && (
        <MoreDetails
          call={activeItem}
          open
          parentTranslationPath={parentTranslationPath}
          onClose={() => setActiveItem(null)}
        />
      )}
    </>
  );
}

export default AgentCallsTableView;
