import React, { useEffect, useState, useContext } from "react";
import clsx from "clsx";
import ReactDOM from "react-dom";
import { Paper, Box } from "@material-ui/core";

import { useSelectedTheme } from "../../../Hooks";
import {
  CustomIconButton,
  CustomInput,
  CustomButton,
  CustomCheckbox,
  EditableDropDown,
} from "..";
import { GetAllUserColumnGroup } from "../../../Services";
import { ContactLayoutContext } from "../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";
// Icons
import { CloseXIcon } from "../../../assets/icons";

// Styles
import useStyles from "./styles";

const RolePinSideTab = ({
  setUpdateSelectedColumnItems,
  open,
  onClose,
  allFormFields,
}) => {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();

  const { filterModalData, setFilterModalData } =
    useContext(ContactLayoutContext);
  const defaultCheckedItems = Array.from({ length: 11 }, (_, i) => i + 1);

  useEffect(() => {
    if (filterModalData?.selectedId) {
      const data = filterModalData?.data?.find(
        (item) => item?.id === filterModalData?.selectedId
      ).fieldsDetails;

      setUpdateSelectedColumnItems(data);

      setCheckedItems(data?.map((element) => element?.id));
    }
  }, [filterModalData?.selectedId]);

  const [checkedItems, setCheckedItems] = useState(defaultCheckedItems);
  const [searchValue, setSearchValue] = useState("");

  // Filtered items based on search input
  const filteredItems = allFormFields?.filter((item) =>
    item?.headerName?.toLowerCase()?.includes(searchValue?.toLowerCase())
  );

  const handleCheckboxChange = (itemId) => {
    if (filterModalData?.selectedId) {
      setFilterModalData((prevData) => ({
        ...prevData,
        selectedValue: "Select saved view",
        selectedId: 0,
      }));
    }

    setCheckedItems((prevChecked) =>
      prevChecked.includes(itemId)
        ? prevChecked.filter((formFieldId) => formFieldId !== itemId)
        : [...prevChecked, itemId]
    );
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleClear = () => {
    setFilterModalData((prevData) => ({
      ...prevData,
      selectedValue: "Select saved view",
      selectedId: 0,
    }));

    setCheckedItems(defaultCheckedItems);
    setSearchValue("");
    const selectedItems = allFormFields?.filter((item) =>
      defaultCheckedItems?.includes(item?.id || item?.formFieldId)
    );
    setUpdateSelectedColumnItems(selectedItems);
  };

  const handleSelectAll = () => {
    if (checkedItems?.length === allFormFields?.length) {
      setCheckedItems([]);
    } else {
      setCheckedItems(allFormFields?.map((item) => item?.formFieldId));
    }
  };

  const handleApply = () => {
    const selectedItems = allFormFields?.filter((item) =>
      checkedItems?.includes(item?.id || item?.formFieldId)
    );

    setUpdateSelectedColumnItems(selectedItems);
  };

  useEffect(() => {
    if (checkedItems?.length > 0) {
      setFilterModalData((prevData) => ({
        ...prevData,
        selectedColumns:
          allFormFields?.filter((item) =>
            checkedItems?.includes(item?.id || item?.formFieldId)
          ) || [],
      }));
    }
  }, [checkedItems?.length]);

  useEffect(() => {
    if (open) {
      const getData = async () => {
        try {
          const res = await GetAllUserColumnGroup();

          setFilterModalData((prevData) => ({
            ...prevData,
            data: res,
          }));
        } catch (error) {
          console.error("Error:", error);
        }
      };

      getData();
    }
  }, [open]);

  return ReactDOM.createPortal(
    <Paper className={clsx(styles.contactSideMenu, { [styles.hidden]: !open })}>
      <Box className={styles.headingContainer}>
        <Box className={styles.titleContainer}>
          <h3 className={styles.title}>Role Pin</h3>

          <CustomIconButton
            variant="text"
            size="none"
            boxShadow="none"
            color="tertiaryColor"
            hideHoverBg
          >
            <CloseXIcon
              onClick={onClose}
              width="20"
              height="20"
              fill={palette.foreground.quinary}
            />
          </CustomIconButton>
        </Box>

        <span>
          Use the role pin feature to keep these columns fixed on the screen for
          easy reference and navigation.
        </span>
      </Box>

      <Box className={styles.dropdownWrapper}>
        <EditableDropDown />

        <CustomInput
          type="text"
          placeholder="Search"
          onChange={handleSearchChange}
          debounceTime={1000}
          value={searchValue}
          style={{ width: "100%" }}
        />
      </Box>

      <Box className={styles.itemsContainer}>
        <Box className={styles.filteredItemWrapper}>
          <CustomCheckbox
            isAllIconSelected={true}
            checked={checkedItems?.length === allFormFields?.length}
            onChange={handleSelectAll}
          />
          <span>Select All</span>
        </Box>

        {filteredItems.map((item) => (
          <Box key={item.formFieldId} className={styles.filteredItemWrapper}>
            <CustomCheckbox
              checked={checkedItems.includes(item.formFieldId || item.id)}
              onChange={() => handleCheckboxChange(item.formFieldId || item.id)}
            />
            <span>{item.headerName}</span>
          </Box>
        ))}
      </Box>

      <Box>
        <Box className={styles.actions}>
          <Box>
            <CustomButton
              size="md"
              variant="text"
              color="tertiary"
              onClick={() => {
                setFilterModalData((prevData) => ({
                  ...prevData,
                  isOpen: true,
                }));
              }}
            >
              Save view
            </CustomButton>
          </Box>

          <Box>
            <CustomButton
              size="md"
              variant="outlined"
              color="secondary"
              onClick={handleClear}
            >
              Clear
            </CustomButton>
            <CustomButton
              onClick={() => {
                handleApply();
                onClose();
              }}
              boxShadow="xs"
              size="md"
              variant="contained"
            >
              Apply
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </Paper>,
    document.getElementById("portal-root")
  );
};

export default RolePinSideTab;
