import React from "react";
import { AutocompleteComponent } from "../../../../../../Components";
import { useTranslation } from "react-i18next";
import { RiskRatingConfigEnum } from "../../../../../../Enums";

export const RiskRatingDuration = ({
  parentTranslationPath,
  translationPath,
  ControlProps,
  DataSetFunctions,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const getDurationTypeEnum = (durationItem) =>
    Object.values(RiskRatingConfigEnum).find(
      (item) => item.value === durationItem?.riskRatingDurationType
    );

  const durationConfigChangeHandler = (
    newValue,
    changedItem,
    changedItemIndex
  ) => {
    const localDurationConfig = [
      ...(ControlProps.selected.durationConfig || []),
    ];

    const itemWithNewValue = {
      ...(changedItem || {}),
      // riskRatingDurationId: 2,
      riskRatingLookupDto: {
        riskRatingLookupsId:
          newValue?.lookupItemId || newValue?.riskRatingLookupsId || null,
        riskRatingLookupName:
          newValue?.lookupItemName || newValue?.riskRatingLookupName || null,
      },
    };

    localDurationConfig[changedItemIndex] = itemWithNewValue;

    ControlProps.onSelectedChange("durationConfig", localDurationConfig);
  };

  return (
    <>
      <h5 className="fw-simi-bold m-0 mb-1 fz-14 mb-1 pb-3 b-bottom">
        {t(`${translationPath}Risk-rating-duration`)}
      </h5>
      {ControlProps.selected.durationConfig
        ? ControlProps.selected.durationConfig.map((item, index) => (
            <div
              className="d-flex fj-start b-bottom"
              key={item?.riskRatingDurationType}
            >
              <div className="w-25 mt-2 pt-1">
                <div className="d-flex fa-center gap-12">
                  <span
                    className={`RateDot ${
                      getDurationTypeEnum(item)?.class || ""
                    }`}
                  ></span>
                  <span className="fw-simi-bold">
                    {getDurationTypeEnum(item)
                    ? `${getDurationTypeEnum(item).name}*`
                    : "" }
                  </span>
                </div>
              </div>
              <div className="w-75 mt-2 mb-1 pt-1">
                <AutocompleteComponent
                  idRef="RiskDurationRef"
                  selectedValues={item?.riskRatingLookupDto}
                  inputPlaceholder={t(`${translationPath}select`)}
                  wrapperClasses="w-50 mb-2"
                  data={ControlProps.data.durationLookups || []}
                  displayLabel={(option) =>
                    option.lookupItemName || option.riskRatingLookupName || ""
                  }
                  multiple={false}
                  withoutSearchButton
                  isWithError
                  onOpen={() => {
                    if (
                      ControlProps.data.durationLookups &&
                      ControlProps.data.durationLookups.length == 0
                    )
                      DataSetFunctions.getRiskDurationLookups();
                  }}
                  onChange={(_, newValue) =>
                    durationConfigChangeHandler(newValue, item, index)
                  }
                />
              </div>
            </div>
          ))
        : null}
    </>
  );
};
