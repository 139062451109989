import { Button, ButtonBase } from "@material-ui/core";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  DialogComponent,
  Inputs,
  RadiosGroupComponent,
  Spinner,
  TabsComponent,
  AutocompleteComponent , 
} from "../../../../Components";
import {
  GetAgentById,
  UpdateAgentById,
} from "../../../../Services/AgentsServices";
import { GetLookupItemsByLookupTypeName } from '../../../../Services' ; 
import { WorkingHoursDialogView } from "./WorkingHoursDialogView";
import { showError, showSuccess } from "../../../../Helper";
import { AgentsTabel } from "./AgentsTabel";
import { AgentRolesTabel } from "./AgentRolesTabel";
import { calculateCapacities } from "./capacityUtils";

const parentTranslationPath = "Agents";
const translationPath = "";
export const AgentsTabelDialogView = ({
  activeItem,
  onCancelClicked,
  relode,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const defaultState = {
    leadCapacity: 0,
    dailyCapacity: 0,
    weeklyCapacity: 0,
    monthlyCapacity: 0,
    forgivenPeriodInMinutes: '',
    vipForgivenessPeriodInMinutes: '',
    isInRotation: false,
    agentRotationSchedules: [],
    reasonId: null,
    notes: null,
    leadPoolCapacity: 0,
    dailyPoolCapacity: 0,
    monthlyPoolCapacity: 0,
    weeklyPoolCapacity:0
  };
  const [state, setState] = useState(defaultState);
  const [allAllStatusReason , setAllStatusReason] = useState([]) ;
  const [agentStatusReason , setAgentStatusReason] = useState(null) ;

  const [isSubmitted, setIsSubmitted] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [response, setResponse] = useState();
  const [res, setRes] = useState([]);
  const [itemIndex, setItemIndex] = useState();
  const getAgentId = useCallback(async (AgentId) => {
    setIsLoading(true);
    const result = await GetAgentById(AgentId);
    if (!(result && result.status && result.status !== 200)) {
      setResponse(result);
      setState((item) => ({
        ...item,
        ...activeItem,
        isInRotation: result?.isInRotation,
        leadCapacity: result?.leadCapacity,
        dailyCapacity: result?.dailyCapacity,
        weeklyCapacity: result?.weeklyCapacity,
        monthlyCapacity: result?.monthlyCapacity,
        vipForgivenessPeriodInMinutes:
          result?.vipForgivenessPeriodInMinutes,
        forgivenPeriodInMinutes: result?.forgivenPeriodInMinutes,
        agentMobile: result?.agentMobile,
        agentEmail: result?.agentEmail,
        agentAgentRoles: result?.agentAgentRoles,
        agentRotationSchedules: result?.agentRotationSchedules,
        reasonId :(result?.reasonId) || (result?.changeReason?.lookupsId ) ,  
        notes : result?.notes 
      }));
     const  reason  = (result && result.changeReason && 
     { lookupItemId :result.changeReason?.lookupsId  , lookupItemName : result.changeReason?.lookupItemName})  || null ;
       setAgentStatusReason((item)=> ({...item ,   reason }))
      setRes(result && result.agentRotationSchedules);
    } else { 
      setAgentStatusReason((item)=> ({...item ,   reason  : null  }))
      setRes(result && result.agentRotationSchedules);

      setResponse({});
    } 

    setIsLoading(false);
  }, []);

  const UpdateAgent = useCallback(async (AgentId, body) => {
    setIsLoading(true);
    if(!body.isInRotation && !(agentStatusReason?.reason))
    {
      showError(t`${translationPath}please-select-off-status-reason`); 
      setIsLoading(false);
      return ; 
    }
    const result = await UpdateAgentById(AgentId, body);
    if (!(result && result.status && result.status !== 200))
    {
      showSuccess(t`${translationPath}Agent-updated-successfully`);
      relode();
      onCancelClicked();
    }
    else showError(t`${translationPath}Agent-updated-falid`);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentStatusReason]);

  const OnSaveClicked = () => {
    const list = [];
    if (
      state &&
      state.agentRotationSchedules &&
      state.agentRotationSchedules.length > 0
    ) {
      state.agentRotationSchedules.map((item) => {
        list.push({
          fromDate: item.fromDate,
          toDate: item.toDate,
          fromTime: item.fromTime,
          toTime: item.toTime,
          mediaNameId: null,
          mediaDetailsId: null,
        });
      });
    }

    UpdateAgent(activeItem.agentId, {
      ...state,
      agentRotationSchedules: list,
    });
  };

  const getAllStatusReason = useCallback(async () => 
  {
    setIsLoading(true);
      const res = await GetLookupItemsByLookupTypeName({ lookUpName : 'OffStatusReason' , pageIndex :1 , pageSize :150});
      if (!((res && res.data && res.data.ErrorId) || !res)) {
          setAllStatusReason(res?.result || []);
      } 
      else 
      setAllStatusReason([]);
      setIsLoading(false) ; 
  });


  useEffect(() => {
    getAgentId(activeItem.agentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem.agentId]);
  const [activeTab, setActiveTab] = useState(0);
  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };

  useEffect(() => {
    setState((item) => ({
      ...item,
      agentRotationSchedules: res,
    }));
  }, [res]);

  const updateCapacity = (type, value) => {
    const capacities = calculateCapacities(type, value);
  
    setState((prevState) => ({
      ...prevState,
      ...capacities,
    }));
  };

  useEffect(() => {
    const currentList = res;
    const index =
      currentList &&
      currentList.findIndex(
        (element) => element.agentRotationScheduleId === itemIndex
      );
    if (index !== -1) {
      currentList && currentList.splice(index, 1);
      setState((items) => ({
        ...items,
        agentRotationSchedules: currentList,
      }));
    } else if (index === -1) {
      setState((items) => ({
        ...items,
        agentRotationSchedules: currentList,
      }));
    }
  }, [itemIndex, res]);

  useEffect(() => {
    getAllStatusReason();
  }, []);

  return (
    <div className='Agents-wrapper view-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='w-100 px-2'>
        <div>
          <div className='seaction-title'>
            {t(`${translationPath}Agent-info`)}
          </div>
          <div className='main-title'>
            <div className='px-3'>
              {(response && response.agentName) || 'N/A'}
            </div>
            <div className='px-3'>
              <span className='mdi mdi-phone px-1' />
              {(response && response.agentMobile) || 'N/A'}
            </div>
            <div className='px-3'>
              <span className='mdi mdi-email-outline px-1' />
              {(response && response.agentEmail) || 'N/A'}
            </div>
          </div>
          <div className='seaction-title'>
            {t(`${translationPath}Rotation-info`)}
          </div>
          <div className='sec-wrapper mb-3'>
            <div className='seaction-one w-100'>
              <div className='inputs-row'>
                <Inputs
                  idRef='LeadRef'
                  labelValue='Lead-Cap'
                  wrapperClasses='m-2'
                  type='number'
                  value={state.leadCapacity}
                  onInputChanged={(e) =>updateCapacity('lead' , parseInt(e.target.value) || 0)}
                  inputPlaceholder='Lead-Cap'
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
                <Inputs
                  idRef='monthlyCapacityRef'
                  labelValue='lead-capacity/monthly'
                  value={state.monthlyCapacity}
                  min={0}
                  wrapperClasses='m-2'
                  type='number'
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(e) => updateCapacity('monthly', parseInt(e.target.value) || 0)}
                />
                <Inputs
                  idRef='weeklyCapacityRef'
                  labelValue='lead-capacity/weekly'
                  value={state.weeklyCapacity}
                  min={0}
                  type='number'
                  wrapperClasses='m-2'
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(e) => updateCapacity('weekly', parseInt(e.target.value) || 0)}
                />
                <Inputs
                  idRef='dailyCapacityRef'
                  labelValue='lead-capacity/daily'
                  value={state.dailyCapacity}
                  min={0}
                  type='number'
                  wrapperClasses='m-2'
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(e) => updateCapacity('daily', parseInt(e.target.value) || 0)}
                />
              </div>
              <div className='inputs-row'>
                <Inputs
                  idRef='LeadRef'
                  labelValue='Lead-pool-Cap'
                  wrapperClasses='m-2'
                  type='number'
                  value={state.leadPoolCapacity}
                  onInputChanged={(e) => updateCapacity('leadPool', parseInt(e.target.value) || 0)}
                  inputPlaceholder='Lead-Cap'
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
                <Inputs
                  idRef='monthlyCapacityRef'
                  labelValue='lead-pool-capacity/monthly'
                  value={state.monthlyPoolCapacity}
                  min={0}
                  wrapperClasses='m-2'
                  type='number'
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(e) => updateCapacity('monthlyPool', parseInt(e.target.value) || 0)}
                />
                <Inputs
                  idRef='weeklyCapacityRef'
                  labelValue='lead-pool-capacity/weekly'
                  value={state.weeklyPoolCapacity}
                  min={0}
                  type='number'
                  wrapperClasses='m-2'
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(e) => updateCapacity('weeklyPool', parseInt(e.target.value) || 0)}
                />
                <Inputs
                  idRef='dailyCapacityRef'
                  labelValue='lead-pool-capacity/daily'
                  value={state.dailyPoolCapacity}
                  min={0}
                  type='number'
                  wrapperClasses='m-2'
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(e) => updateCapacity('dailyPool', parseInt(e.target.value) || 0)}
                />
              </div>
              <div className='inputs-row'>
                <Inputs
                  idRef='Normal-contacted-expirationRef'
                  labelValue='Normal-contacted-expiration-period'
                  value={state.forgivenPeriodInMinutes}
                  onInputChanged={(e) => {
                    setState({
                      ...state,
                      forgivenPeriodInMinutes: e.target.value,
                    });
                  }}
                  wrapperClasses='m-2'
                  inputPlaceholder='Normal-contacted-expiration'
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
                {/* </div> */}
                {/* <div className='input-wraper'> */}
                <Inputs
                  idRef='VIP-contactedRef'
                  inputPlaceholder='VIP-contacted'
                  labelValue='VIP-contacted-expiration-period'
                  value={state.vipForgivenessPeriodInMinutes}
                  onInputChanged={(e) => {
                    setState({
                      ...state,
                      vipForgivenessPeriodInMinutes: e.target.value,
                    });
                  }}
                  wrapperClasses='m-2'
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
            </div>
            </div>
          <div className='d-flex d-flex-h-start'>
            <div className='w-25'>
              <RadiosGroupComponent
                data={[
                  { value: true, label: t(`${translationPath}ON`) },
                  { value: false, label: t(`${translationPath}Off`) },
                ]}
                idRef='Actions'
                onSelectedRadioChanged={() =>
                  setState({
                    ...state,
                    isInRotation: !state.isInRotation,
                    reasonId: !state.isInRotation ? null : state.reasonId,
                    notes: !state.isInRotation ? null : state.notes,
                })
                }
                value={state.isInRotation}
                labelValue='In-Rotation'
                name='Active'
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                labelInput='label'
                valueInput='value'
                themeClass='theme-default'
              />
              </div>
            {!state.isInRotation && (
              <div className='d-flex d-flex-h-start w-75'>
                <div className='ml-2 mr-2 w-50'>
                  <AutocompleteComponent
                  idRef='reasonRef'
                  labelClasses='Requierd-Color'
                  labelValue={t(`${translationPath}reason`)}
                  data={allAllStatusReason}
                    selectedValues={
                      agentStatusReason && agentStatusReason.reason
                    }
                    value={agentStatusReason && agentStatusReason.reason}
                  multiple={false}
                    displayLabel={(option) =>
                      (option && option.lookupItemName) || ''
                    }
                    chipsLabel={(option) =>
                      (option && option.lookupItemName) || ''
                    }
                  withoutSearchButton
                    onChange={(e, newValue) => {
                      setAgentStatusReason((item) => ({
                        ...item,
                        reason: newValue,
                      }));
                      setState({
                        ...state,
                        reasonId: newValue?.lookupItemId || null,
                      });
                  }}
                  isLoading={isLoading}
                  withLoader
                    helperText={t(
                      `${translationPath}please-select-off-status-reason`
                    )}
                    error={!agentStatusReason?.reason}
                  isWithError
                  />
                  </div>
                <div className='w-50'>
                    <Inputs
                      idRef='remarkRef'
                      labelValue='remarks'
                      multiline
                      rows={4}
                      value={state.notes || ''}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onInputChanged={(event) => {
                      const { value } = event.target;
                      setState({ ...state, notes: value });
                      }}
                    /> 
                    </div>
                  </div>
                 )}
            </div>
          <div>
            <TabsComponent
              data={[{ tab: 'Working-Hours' }, { tab: 'AgentRoles' }]}
              labelInput='tab'
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              themeClasses='theme-curved'
              currentTab={activeTab}
              onTabChanged={onTabChanged}
            />
          </div>
          {activeTab === 0 && (
            <div className='m-4'>
              <AgentsTabel
                Data={state && state.agentRotationSchedules}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                Deletedfile={(items) =>
                  setItemIndex(items.agentRotationScheduleId)
                }
              />
            </div>
          )}
          {activeTab === 1 && (
            <div className='m-4'>
              <AgentRolesTabel
                Data={state && state.agentAgentRoles}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            </div>
          )}
          {(activeTab === 0 && (
            <div className='action-wraper'>
              <ButtonBase
                className='btns-icon theme-solid bg-secondary mt-1 mx-2'
                onClick={() => setOpenDialog(true)}
                id='action_add-new_plus'
              >
                <span className='mdi mdi-plus ' />
              </ButtonBase>
              <div>{t(`${translationPath}add-new`)}</div>
            </div>
          )) ||
            ''}
        </div>
        <div className='dialog-footer-wrapper  MuiDialogActions-spacing'>
          <div className='save-cancel-wrapper d-flex-v-center-h-end flex-wrap p-2'>
            <div className='cancel-wrapper d-inline-flex-center'>
              <Button
                onClick={onCancelClicked}
                id='cancel_AgentsTabelDialogView'
                className='cancel-btn-wrapper btns theme-transparent c-primary'
              >
                <span className='MuiButton-label'>
                  <span>{t(`${translationPath}cancel`)}</span>
                </span>
                <span className='MuiTouchRipple-root' />
              </Button>
            </div>
            <div className='save-wrapper d-inline-flex-center'>
              <Button
                id='save_AgentsTabelDialogView'
                onClick={OnSaveClicked}
                className='btns theme-solid w-100 mx-2 mb-2'
              >
                <span className='MuiButton-label'>
                  <span>{t(`${translationPath}save`)}</span>
                </span>
                <span className='MuiTouchRipple-root' />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <DialogComponent
        isOpen={openDialog}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        titleClasses='DialogComponent-WorkingHoursDialogView'
        wrapperClasses='wrapperClasses-WorkingHoursDialogView'
        titleText='add-new-Working-hours'
        saveClasses='btns theme-solid w-100 mx-2 mb-2'
        onCloseClicked={() => setOpenDialog(false)}
        maxWidth='md'
        dialogContent={
          <>
            <WorkingHoursDialogView
              isSubmitted={isSubmitted}
              onCancelClicked={() => setOpenDialog(false)}
              onsave={(data, schema) => {
                setIsLoading(true);
                setIsSubmitted(true);
                if (schema && schema.error) {
                  showError(t('Shared:please-fix-all-errors'));
                  return;
                }
                setRes((items) => {
                  items.push({
                    agentRotationScheduleId: data.agentRotationScheduleId,
                    fromDate: data.fromDate,
                    toDate: data.toDate,
                    fromTime: moment(data.fromTime).format('HH:mm'),
                    toTime: moment(data.toTime).format('HH:mm'),
                    //  mediaName: data.mediaName,
                    // mediaDetails: data.mediaDetails,
                    mediaNameId: null,
                    mediaDetailsId: null,
                  });
                  return [...items];
                });
                setIsSubmitted(false);
                setIsLoading(false);
                setOpenDialog(false);
              }}
            />
          </>
        }
      />
    </div>
  );
};

AgentsTabelDialogView.propTypes = {
  activeItem: PropTypes.instanceOf(Object).isRequired,
  onCancelClicked: PropTypes.func.isRequired,
  relode: PropTypes.func.isRequired,
};
