import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import './BedsAndBathsFilter.scss';
import { styled } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';

const StyledButton = withStyles({
  root: {
    display: 'flex',
    fontFamily: 'inherit',
    minWidth: '150px',
    height: '32px',
    padding: '10px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    textTransform: 'capitalize',
    background: '#FFF',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'inherit',
      borderColor: '#D0D5DD',
      boxShadow: 'none',
    },
    '& .bed-bath-button': {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '15px',
      color: '#344054',
    },
  },
})(Button);
const DropdownIconWrapper = styled('span')({
  display: 'flex',
  flex: '1 1 100%',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#8e8e93',
  fontSize: '22px',
  '&::before': {
    fontSize: '22px',
  },
});
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    padding: '5px',
  },
}))(MenuItem);

const RadioItem = ({ value, selected, onClick }) => (
  <span
    className={`radio-item-filter ${selected ? 'selected' : ''}`}
    onClick={onClick}
  >
    {value}
  </span>
);

export const BedsAndBathFilter = ({
  parentTranslationPath,
  selected,
  setSelected,
  restBedAndBath,
  filterBadsAndBaths,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const values = useMemo(() => Array.from({ length: 8 }, (_, i) => i + 1), []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (event) => {
    if (anchorEl && anchorEl.contains(event?.target)) {
      return;
    }
    setAnchorEl(null);
    setOpen(false);
  };
  const handleReset = () => {
    restBedAndBath();
  };

  const handleSelection = async (type, value) => {
    const types = {
      bedrooms: {
        from: 'bedrooms_from',
        to: 'bedrooms_to',
      },
      bathrooms: {
        from: 'bathrooms_from',
        to: 'bathrooms_to',
      },
    };

    if (value === 8) {
      setSelected({
        id: type,
        value: { from: value, to: 100 },
      });
      filterBadsAndBaths(types[type], { from: value, to: 100 });
    } else if (value < selected[type]?.from) {
      setSelected({
        id: type,
        value: { from: value, to: value },
      });
      await filterBadsAndBaths(types[type], { from: value });
    } else if (selected[type]?.from) {
      setSelected({
        id: type,
        value: { from: selected[type].from, to: value },
      });
      await filterBadsAndBaths(types[type], {
        from: selected[type].from,
        to: value,
      });
    } else {
      setSelected({
        id: type,
        value: { from: value, to: value },
      });
      await filterBadsAndBaths(types[type], { from: value });
    }
  };

  const getButtonLabel = () => {
    const { bedrooms, bathrooms } = selected;
    const formatLabel = (type, label) =>
      type?.from
        ? type.from === type.to
          ? `${type.from} ${label}`
          : `${type.from} - ${type.to} ${label}`
        : '';

    const bedLabel = formatLabel(bedrooms, 'Beds');
    const bathLabel = formatLabel(bathrooms, 'Baths');

    if (!bedLabel && !bathLabel) {
      return 'beds & baths';
    }

    return `${bedLabel}${bathLabel ? `; ${bathLabel}` : ''}`;
  };

  return (
    <div>
      <StyledButton
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='outlined'
        color='primary'
        onClick={handleClick}
        endIcon={
          <DropdownIconWrapper
            className={`mdi ${open ? 'mdi-chevron-up' : 'mdi-chevron-down'}`}
          />
        }
      >
        <span className='bed-bath-button'>{getButtonLabel()}</span>
      </StyledButton>

      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className='filter-section-wrapper'>
          <legend className='filter-section-title'>{t('bedrooms')}</legend>
          <div className='values-row'>
            {values.map((i) => (
              <StyledMenuItem
                key={`bedroom-${i}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelection('bedrooms', i);
                }}
              >
                <RadioItem
                  value={i === 8 ? '+8' : i}
                  selected={
                    selected.bedrooms?.from <= i && selected.bedrooms?.to >= i
                  }
                />
              </StyledMenuItem>
            ))}
          </div>
        </div>

        <div className='filter-section-wrapper'>
          <legend className='filter-section-title'>{t('bathrooms')}</legend>
          <div className='values-row'>
            {values.map((i) => (
              <StyledMenuItem
                key={`bathroom-${i}`}
                onClick={() => handleSelection('bathrooms', i)}
              >
                <RadioItem
                  value={i === 8 ? '+8' : i}
                  selected={
                    selected.bathrooms?.from <= i && selected.bathrooms?.to >= i
                  }
                />
              </StyledMenuItem>
            ))}
          </div>
        </div>
        <div className='d-flex fj-end'>
          <ButtonBase
            className='btns'
            onClick={() => {
              handleReset();
            }}
          >
            {t('Reset')}
          </ButtonBase>
        </div>
      </StyledMenu>
    </div>
  );
};
