import React, { useCallback, useReducer, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./ShareDocumentsDialog.scss";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  ButtonBase,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import { Spinner } from "../../Components";
import { getPublicDownloadableLink, showError, showSuccess } from "../../Helper";
import {
  ShareScopeDocuments,
} from "../../Services";
import ShareViaEmailForm from "./DialogForms/ShareViaEmailForm";
import { PickMethodForm } from "./DialogForms/PickMethodForm";
import ShareViaWhatsAppForm from "./DialogForms/ShareViaWhatsAppForm";
import { ShareMethodsDataEnum } from "../../Enums";

export const ShareDocumentsDialog = ({
  shareViaExternalAPI,
  activeItem,
  theme,
  onSave,
  open,
  close,
  contactId,
  unitId,
}) => {
  const parentTranslationPath = "UnitsProfileManagementView";
  const translationPath = "";
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const [isLoadingSpinner, setisLoadingSpinner] = useState(false);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [selected, setSelected] = useReducer(reducer, {
    shareVia: null,
    emailSubject: null,
    emailBody: null,
    initialWhatsappMsgBody: '',
    whatsappMsgBody: null,
    recipient: {
      Email: {
        leads: [],
        systemUsers: [],
      },
      WhatsApp: {
        lead: null,
        systemUser: null,
      }
    }
  });


  const getLeadsWithMissingEmails = () => {
    const leadList = selected.recipient?.Email?.leads
      ? selected.recipient?.Email?.leads.filter((item) => !item.contactEmailAddress)
      : [];

    if (leadList.length != 0) {
      const leadNamesList = leadList.map(
        (item) => item.lead?.contact_name?.name
      );
      const leadNamesString = leadNamesList.reduce(
        (result, item) => result + item + " , ",
        ""
      );
      return leadNamesString;
    }
  };

  const getRecipientWithMissingWhatsAppNo = () => {
    const isNumberExist = selected.recipient?.WhatsApp?.systemUser?.whatsAppNumber ||
      selected.recipient?.WhatsApp?.lead?.contactWhatsApp;
    return !isNumberExist;
  };

  const getUsersWithMissingEmails = () => {
    const usersList = selected.recipient?.Email?.systemUsers
      ? selected.recipient?.Email?.systemUsers.filter((item) => !item.email)
      : [];

    if (usersList.length != 0) {
      const userNamesList = usersList.map((item) => item.fullName);
      const userNamesString = userNamesList.reduce(
        (result, item) => result + item + " , ",
        ""
      );
      return userNamesString;
    }
  };

  const WhatsAppFormSchema = Joi.object({
    recipient: Joi.any()
      .custom((value, helpers) => {
          if (!selected.recipient?.WhatsApp?.lead && !selected.recipient?.WhatsApp?.systemUser)
            return helpers.error("state.required");

          if (getRecipientWithMissingWhatsAppNo())
            return helpers.error("recipient-With-Missing-WhatsAppNo-error");

          return value;
      })
      .messages({
        "state.required": `Recipient is required (one at least)`,
        "recipient-With-Missing-WhatsAppNo-error": `There Are recipient With Missing WhatsApp Number`,
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(selected);

  const EmailFormSchema = Joi.object({
    emailSubject: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}emailSubject-is-required`),
        "string.empty": t(`${translationPath}emailSubject-is-required`),
      }),
    emailBody: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}emailBody-is-required`),
        "string.empty": t(`${translationPath}emailBody-is-required`),
      }),
    recipient: Joi.any()
      .custom((value, helpers) => {
        if (selected.recipient?.Email?.leads?.length === 0 && selected.recipient?.Email?.systemUsers?.length === 0)
          return helpers.error("state.required");

        if (getLeadsWithMissingEmails() || getUsersWithMissingEmails())
          return helpers.error("recipient-With-Missing-Email-error");

        return value;
      })
      .messages({
        "state.required": `Recipient is required (one at least)`,
        "recipient-With-Missing-Email-error": `There Are recipient With Missing Email Address`,
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(selected);



  const saveHandler = async (event) => {
    event.preventDefault();

    if (selected.shareVia?.key === ShareMethodsDataEnum.Email.key && 
      EmailFormSchema?.error?.message) {
      showError(t(`${translationPath}${EmailFormSchema.error.message}`));
      return;
    } else if (selected.shareVia?.key === ShareMethodsDataEnum.WhatsApp.key &&
      WhatsAppFormSchema?.error?.message) {
      showError(t(`${translationPath}${WhatsAppFormSchema.error.message}`));
      return;
    }


    if (shareViaExternalAPI) {
      const body = getShareDocsAPIBody();
      onSave(body);
    } else if(selected.shareVia?.key === ShareMethodsDataEnum.Email.key) shareScopeDocuments();
    else if(selected.shareVia?.key === ShareMethodsDataEnum.WhatsApp.key) shareViaWhatsApp();
  };

  const getShareDocsAPIBody = () => {
    const mappedSystemUsers =
      (selected.recipient?.Email?.systemUsers && selected.recipient?.Email?.systemUsers.map((item) => item?.id)) ||
      [];
    const mappedLeads =
      (selected.recipient?.Email?.leads && selected.recipient?.Email?.leads.map((item) => item?.leadId)) || [];

    const mappedFiles =
      activeItem && activeItem.documentName && activeItem.documentId
        ? [
            {
              fileName: activeItem.documentName,
              uuid: activeItem.documentId,
            },
          ]
        : [];
    const mappedLinks =
      activeItem && activeItem.documentLink
        ? [ activeItem.documentLink ]
        : [];

    return {
      systemUsersId: mappedSystemUsers,
      leadsId: mappedLeads,
      files: mappedFiles,
      links: mappedLinks,
      emailSubject: selected.emailSubject,
      emailBody: selected.emailBody,
      categoryId: activeItem?.categoryId,
      contactId,
      unitId,
    };
  };

  const shareScopeDocuments = useCallback(async () => {
    setisLoadingSpinner(true);
    const body = getShareDocsAPIBody();

    const res = await ShareScopeDocuments(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}document-sent-successfully`);
      if (onSave) onSave();
    } else showError(t`${translationPath}sending-document-failed`);

    setisLoadingSpinner(false);
  });

  
  const getWhatsAppDocUrl = () => {
    let URL = '';
    const fileURL = activeItem?.documentId
    ? getPublicDownloadableLink(activeItem?.documentId)
    : '';
    const isLink = activeItem?.documentLink;
    URL = isLink ? activeItem?.documentLink : fileURL;

    const URLWithComment = `\nPlease find the document you requested here: \n\n ${URL}`
    return URLWithComment;    
  }

  const shareViaWhatsApp = () => {
    const WhatsAppMsg = selected.whatsappMsgBody || selected.initialWhatsappMsgBody || '';
    const UrlEncodedMessage =  encodeURIComponent(WhatsAppMsg);
    const whatsAppNumber = selected.recipient?.WhatsApp?.systemUser?.whatsAppNumber ||
    selected.recipient?.WhatsApp?.lead?.contactWhatsApp || null;
    const UrlToWhatsApp = `https://wa.me/${whatsAppNumber}?text=${UrlEncodedMessage}`

    window.open(UrlToWhatsApp);
  }
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          close();
        }}
        className="Parking-management-dialog-wrapper ShareDocDialog"
      >
        <Spinner isActive={isLoadingSpinner} isAbsolute />
        <form noValidate onSubmit={saveHandler}>
          <DialogTitle id="alert-dialog-slide-title">
            <span>{t(`${translationPath}share-document`)}</span>
            <span className="fz-14">{`${selected.shareVia? ` (Via ${selected.shareVia?.key} )` : ''}`}</span>
          </DialogTitle>
          <DialogContent>
            {!selected.shareVia ? (
              <PickMethodForm
                activeCard={selected.shareVia}
                onActiveCardChange={(item) => setSelected({ id: "shareVia", value: item })}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            ) : null}
            {selected.shareVia?.key === ShareMethodsDataEnum.Email.key ? 
            (<ShareViaEmailForm
              selected={selected}
              setSelected={setSelected}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />)
            : null}
            {selected.shareVia?.key === ShareMethodsDataEnum.WhatsApp.key ? 
            (<ShareViaWhatsAppForm
              selected={selected}
              setSelected={setSelected}
              getWhatsAppDocUrl={getWhatsAppDocUrl}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />)
            : null}
          </DialogContent>
          {selected.shareVia ? (
            <DialogActions>
              <ButtonBase
                onClick={() => close()}
                className={`btns ${
                  theme == "primary"
                    ? "theme-solid bg-cancel"
                    : "theme-propx outlined"
                }`}
              >
                {t(`${translationPath}cancel`)}
              </ButtonBase>
              <ButtonBase
                className={`btns ${
                  theme == "primary" ? "theme-solid" : "theme-propx solid"
                }`}
                type="submit"
              >
                {t(`${translationPath}send`)}
              </ButtonBase>
            </DialogActions>
          ) : null}
        </form>
      </Dialog>
    </div>
  );
};
ShareDocumentsDialog.propTypes = {
  activeItem: PropTypes.instanceOf(Object),
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isedit: PropTypes.bool.isRequired,
  shareViaExternalAPI: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(["primary", "propx"]),
};
ShareDocumentsDialog.defaultProps = {
  activeItem: null,
  shareViaExternalAPI: false,
  theme: "primary",
};
