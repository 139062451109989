import React, { useCallback, useState } from "react";
import { Tables } from "../../../../../../../Components";
import { TableActions } from "../../../../../../../Enums";
import { useTranslation } from "react-i18next";
import { DeleteSourceFile } from "../../../../../../../Services";
import { showError, showSuccess } from "../../../../../../../Helper";
import { EditSourceFileDialog } from "./EditSourceFileDialog";
export const HistoryContactDetailsTable = ({
    contactId,
    contactDetailsData,
    parentTranslationPath,
    onReload
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [activeItem, setActiveItem] = useState(null);
    const [openDialogEditSourceFile, setOpenDialogEditSourceFile] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const headerData = [
        {
            label: 'Source-File-name',
            input: 'sourceFileName',
            isDate: false,
            component: (item) => item.sourceFileName ? <span>{item.sourceFileName}</span> : 'N/A',
        }, {
            label: 'Source-File-Year',
            input: 'sourceFileYear',
            isDate: false,
            component: (item) => item.sourceFileYear ? <span>{item.sourceFileYear}</span> : 'N/A',
        }, {
            label: 'Source-file-date',
            input: 'sourceFileDate',
            isDate: true,
            component: (item) => item.sourceFileDate ? <span>{item.sourceFileDate}</span> : 'N/A',
        }, {
            label: 'Source-by',
            input: 'sourceBy',
            isDate: false,
            component: (item) => item.sourceBy ? <span>{item.sourceBy}</span> : 'N/A',
        }, {
            label: 'Comunity-Source-file',
            input: 'communitySourceFile',
            isDate: false,
            component: (item) => item.communitySourceFile ? <span>{item.communitySourceFile}</span> : 'N/A',
        }, {
            label: 'Property-Source-File',
            input: 'propertySourceFile',
            isDate: false,
            component: (item) => item.propertySourceFile ? <span>{item.propertySourceFile}</span> : 'N/A',

        }, {
            label: 'Created-by',
            input: 'createdby',
            isDate: false,
            component: (item) => item.createdby ? <span>{item.createdby}</span> : 'N/A',
        }, {
            label: 'Created-On',
            input: 'createdOn',
            isDate: true,
            component: (item) => item.createdOn ? <span>{item.createdOn}</span> : 'N/A',
        }
    ]
    const tableActionClicked = useCallback((actionEnum, item) => {
        if (actionEnum === TableActions.editText.key) {
            setActiveItem(item);
            setOpenDialogEditSourceFile(true);
        }
        if (actionEnum === TableActions.delete.key) {
            setActiveItem(item);
            deleteSourceFile(item?.sourceFileId)
        }
    }, []);
    const getActionTableWithPermissions = () => {
        const list = [];
        list.push({
            enum: TableActions.editText.key,
            isDisabled: false,
            externalComponent: null,
        });
        list.push({
            enum: TableActions.delete.key,
            isDisabled: false,
            externalComponent: null,
        });
        return list;
    };
    const deleteSourceFile = useCallback(async (sourceFileId) => {
        setIsLoading(true)
        const res = await DeleteSourceFile(sourceFileId)
        if (res === true) {
            showSuccess(t(`delete-source-file-successfully`));
            onReload()
        } else {
            showError(t(`failed-to-delete-source-file`));
        }
    })
    return (
        <div className="mt-4">
            <div className='title-section'>
                {t('Source-file-Table')}
            </div>
            <Tables
                headerData={headerData}
                data={contactDetailsData}
                parentTranslationPath={parentTranslationPath}
                translationPath={''}
                itemsPerPage={25}
                activePage={0}
                defaultActions={getActionTableWithPermissions()}
                actionsOptions={{
                    onActionClicked: tableActionClicked,
                }}

            />
            {
                openDialogEditSourceFile && (
                    <EditSourceFileDialog
                        activeItem={activeItem}
                        onOpen={openDialogEditSourceFile}
                        parentTranslationPath={parentTranslationPath}
                        onClose={() => setOpenDialogEditSourceFile(false)}
                        onSave={()=>{onReload()}}
                    />
                )
            }
        </div>
    )
}