import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { DatePickerComponent, Inputs, PermissionsComponent, Spinner, Tables } from '../../../../../../Components';
import { useTranslation } from "react-i18next";
import { useTitle } from '../../../../../../Hooks';
import moment from 'moment';
import { ButtonBase } from '@material-ui/core';
import { LeadsAdvanceSearchTest, GetSourceFileByContactId, CreateSourceFile } from '../../../../../../Services';
import { GetParams, GlobalHistory, bottomBoxComponentUpdate, showWarn } from '../../../../../../Helper';
import PropTypes from 'prop-types';
import { showSuccess, showError } from '../../../../../../Helper';
import { ContactsPermissions } from '../../../../../../Permissions';
import { HistoryContactDetailsTable } from './ContactProfileSourceFileUtilites/HistoryContactDetailsTable';
import { object } from 'joi';

const translationPath = '';

export const ContactProfileSourceFileComponent = ({
    parentTranslationPath,
    isOpen,
}) => {

    const { t } = useTranslation(parentTranslationPath);
    useTitle(t("File-Source"));
    const contactId = +GetParams('id');
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [leadArrayInfo, setLeadArrayInfo] = useState([]);
    const [dataFiled, setdataFiled] = useState([]);
  
    
    const reducer = useCallback((state, action) => {
        if (action.id !== "edit") return { ...state, [action.id]: action.value };
        return {
            ...action.value,
        };
    }, []);
    const [filter, setFilter] = useReducer(reducer, {
        contactId: +GetParams('id'),
        sourceFileName: "",
        sourceFileYear: "",
        communitySourceFile: "",
        propertySourceFile: "",
        sourceBy: "",
        sourceFileDate: "",
    });


    const [selected, setSelected] = useReducer(reducer, {
        sourceFileName: "",
        sourceFileYear: "",
        communitySourceFile: "",
        propertySourceFile: "",
        sourceBy: "",
        sourceFileDate: "",
    })
  

    const leadTableData = useCallback(async (leadId) => {
        setIsLoading(true);
        const isForLog = true;

        let body = {
            criteria: {
                contactId: [{ searchType: 1, value: leadId }]
            },
            filterBy: 'createdOn',
            orderBy: 2,
        };

        const res = await LeadsAdvanceSearchTest({ pageIndex: 0, pageSize: 100, isForLog }, body);
        if (!(res && res.status && res.status !== 200)) {
            if (res && res.totalCount > 0) {
                const leadsData = res.result.map(lead => {
                    const { leadJson, leadId } = lead;
                    const { lead: leadDetails } = JSON.parse(leadJson);
                    return { leadId, lead: leadDetails };
                });
                setLeadArrayInfo(leadsData);

            }
            else {
                setLeadArrayInfo(null);
            }
        }
        setIsLoading(false);
    });
    const cancelHandler = () => {
        const parentContact = localStorage.getItem('parentContact')
        if (GetParams('isAssociated') && parentContact) {
            const parsParentContact = JSON.parse(parentContact);
            parsParentContact.id && parsParentContact.userTypeId({
                id: JSON.stringify(parsParentContact.id),
                userTypeId: JSON.stringify(parsParentContact.userTypeId),
            });
        }
        GlobalHistory.goBack();
    };

    const validateInputs = useCallback(() => {
        const selectedArray = Object.values(selected);
        return selectedArray.findIndex(item => !item); 
    }, [selected]);

    const saveHandler = useCallback(async () => {
        if (!validateInputs()) {
            showWarn(t(`${translationPath}fill-at-least-Source-file-name-to-complete-create-source-file`))
            return  
        }
        const res = await CreateSourceFile({
            body: filter
        })
        if (!(res && res.status && res.status !== 200)) {
            showSuccess(t(`${translationPath}create-source-file-successfully`));
            getSourceFileByContactId()
        }
        else {
            showError(t(`${translationPath}failed-to-create-source-file`));
        }

    })


    const getSourceFileByContactId = useCallback(async () => {
        const res = await GetSourceFileByContactId({ contactId })
        if (!(res && res.status && res.status !== 200)) {
            setdataFiled(res)
        }
    })
    useEffect(() => {
        if (contactId && activeTab === 0)
            getSourceFileByContactId(contactId)
    }, [activeTab]);


    useEffect(() => {
        if (contactId && activeTab === 0)
            leadTableData(contactId);
    }, [activeTab]);


    useEffect(() => {
        if (dataFiled) {
            setSelected({
                id: "edit",
                value: {
                    sourceFileName: dataFiled.sourceFileName || "",
                    sourceFileYear: dataFiled.sourceFileYear || "",
                    communitySourceFile: dataFiled.communitySourceFile || "",
                    propertySourceFile: dataFiled.propertySourceFile || "",
                    sourceBy: dataFiled.sourceBy || "",
                    sourceFileDate: dataFiled.sourceFileDate || null,
                    contactId: +GetParams('id')
                }
            })
            setFilter({
                id: "edit",
                value: {
                    sourceFileName: dataFiled.sourceFileName || "",
                    sourceFileYear: dataFiled.sourceFileYear || "",
                    communitySourceFile: dataFiled.communitySourceFile || "",
                    propertySourceFile: dataFiled.propertySourceFile || "",
                    sourceBy: dataFiled.sourceBy || "",
                    sourceFileDate: dataFiled.sourceFileDate || null,
                    contactId: +GetParams('id')
                }
            });
        }
    }, [dataFiled]);

    useEffect(() => {
        if (!isOpen) {
            bottomBoxComponentUpdate(
                <div className='d-flex-v-center-h-end flex-wrap'>
                    <ButtonBase className='btns theme-transparent mb-2' onClick={cancelHandler}>
                        <span>{t('Shared:cancel')}</span>
                    </ButtonBase>

                    <PermissionsComponent
                        permissionsList={Object.values(ContactsPermissions)}
                        permissionsId={ContactsPermissions.EditContactSourceFile.permissionsId}
                    >
                        <ButtonBase className='btns theme-solid mb-2' onClick={() => saveHandler()}>
                            <span>{t('Shared:create')}</span>
                        </ButtonBase>
                    </PermissionsComponent>
                </div>
            );
        }
    });

    return (
        <div className=" my-3 childs-wrapper">
            <Spinner isActive={isLoading} isAbsolute />
            <div className='title-section'>
                {t('File-Source')}
            </div>
            <PermissionsComponent
                permissionsList={Object.values(ContactsPermissions)}
                permissionsId={ContactsPermissions.EditContactSourceFile.permissionsId}
            >
                <div className="tabs-wrapper d-flex">
                    <div className="w-24">
                        <div className="mb-3">
                            <span className="b-400">
                                {t(`${translationPath}Source-File-name`)}
                            </span>
                        </div>
                        <div className="mt-2 mb-1 pt-1">
                            <Inputs
                                // isDisabled={}
                                value={selected.sourceFileName || ""}
                                idRef="SourceFilenameRef"
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                                onInputChanged={(event) => {
                                    const SourceFilename = event?.target?.value;
                                    setSelected({ id: "sourceFileName", value: SourceFilename })
                                    setFilter({ id: "sourceFileName", value: SourceFilename })
                              
                                }}
                            />
                        </div>
                    </div>
                    <div className="w-24">
                        <div className="mb-3">
                            <span className="b-400">
                                {t(`${translationPath}Source-File-Year`)}
                            </span>
                        </div>
                        <div className="mt-2 mb-1 pt-1">
                            <Inputs
                                // isDisabled={}
                                value={selected.sourceFileYear || ""}
                                idRef="SourceFileYearRef"
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                                isWithError
                                onInputChanged={(event) => {
                                    const SourceFileYear = event?.target?.value;
                                    setSelected({ id: "sourceFileYear", value: SourceFileYear })
                                    setFilter({ id: "sourceFileYear", value: SourceFileYear })
                                   
                                }}
                            />
                        </div>
                    </div>
                    <div className="w-24">
                        <div className="mb-3">
                            <span className="b-400">
                                {t(`${translationPath}Source-file-date`)}
                            </span>
                        </div>
                        <div className=" mt-2 mb-1 pt-1">
                            <DatePickerComponent
                                // isDisabled={}
                                idRef="SourceFselectedileDateRef"
                                placeholder="DD/MM/YYYY"
                                value={selected.sourceFileDate || null}
                                onDateChanged={(newValue) => {
                                    const sourceFileDate = (newValue && moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) || null
                                    setSelected({ id: "sourceFileDate", value: sourceFileDate })
                                    setFilter({ id: "sourceFileDate", value: sourceFileDate })
                                   
                                }
                                }

                            />
                        </div>
                    </div>
                    <div className="w-24">
                        <div className="mb-3">
                            <span className="b-400">
                                {t(`${translationPath}Source-by`)}
                            </span>
                        </div>
                        <div className=" mt-2 mb-1 pt-1">
                            <Inputs
                                // isDisabled={}
                                value={selected.sourceBy || ""}
                                idRef="SourceByRef"
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                                isWithError
                                onInputChanged={(event) => {
                                    const SourceBy = event?.target?.value;

                                    setSelected({ id: "sourceBy", value: SourceBy })
                                    setFilter({ id: "sourceBy", value: SourceBy })
                                 
                                }}
                            />
                        </div>
                    </div>
                    <div className="w-24">
                        <div className="mb-3">
                            <span className="b-400">
                                {t(`${translationPath}Comunity-Source-file`)}
                            </span>
                        </div>
                        <div className=" mt-2 mb-1 pt-1">
                            <Inputs
                                // isDisabled={}
                                value={selected.communitySourceFile || ""}
                                idRef="CommunitySourceFileRef"
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                                isWithError
                                onInputChanged={(event) => {
                                    const CommunitySourceFile = event?.target?.value;

                                    setSelected({ id: "communitySourceFile", value: CommunitySourceFile })
                                    setFilter({ id: "communitySourceFile", value: CommunitySourceFile })
                                   
                                }}
                            />
                        </div>
                    </div>
                    <div className="w-24">
                        <div className="mb-3">
                            <span className="b-400">
                                {t(`${translationPath}Property-Source-File`)}
                            </span>
                        </div>
                        <div className=" mt-2 mb-1 pt-1">
                            <Inputs
                                // isDisabled={}
                                value={selected.propertySourceFile || ""}
                                idRef="PropertySourceFileRef"
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                                isWithError
                                onInputChanged={(event) => {
                                    const PropertySourceFile = event?.target?.value;
                                    setSelected({ id: "propertySourceFile", value: PropertySourceFile })
                                    setFilter({ id: "propertySourceFile", value: PropertySourceFile })
                                   
                                }}
                            />
                        </div>
                    </div>
                </div>
            </PermissionsComponent>
            <div className="dialog-content-item w-100 px-4 py-3 mb-5">
                <div className='title-section'>
                    {t('Lead-Table')}
                </div>
                <Tables
                    data={leadArrayInfo || []}
                    headerData={[
                        {
                            id: 1,
                            label: "Lead ID",
                            input: "leadId",
                            key: 0,
                            component: (item) => item?.leadId ? <span>{item?.leadId}</span> : 'N/A',
                        },
                        {
                            id: 2,
                            label: "GCLID",
                            input: "lead.google_gclid",
                            key: 1,
                            component: (item) => item?.lead?.google_gclid ? <span>{item?.lead?.google_gclid}</span> : 'N/A',
                        },
                        {
                            id: 3,
                            label: "Campaign Name",
                            input: "lead.campaign_name.name",
                            key: 2,
                            component: (item) => item?.lead?.campaign_name?.name ? <span>{item?.lead?.campaign_name?.name}</span> : 'N/A',
                        },
                    ]}
                    defaultActions={[]}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                />
                <HistoryContactDetailsTable
                    contactId={contactId}
                    contactDetailsData={dataFiled}
                    parentTranslationPath={parentTranslationPath}
                    onReload={() => { getSourceFileByContactId() }}
                />
            </div>

        </div>
    );
};
ContactProfileSourceFileComponent.defaultProps = {
    isOpen: false,
};
ContactProfileSourceFileComponent.propTypes = {
    isOpen: PropTypes.bool,
};