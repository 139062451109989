import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography, Divider, Box } from '@material-ui/core';
import useStyles from './styles';
import { ArrowLeftIcon } from '../../../assets/icons';
import { useSelectedTheme } from '../../../Hooks';
import { CustomButton } from '../CustomButton';
import { useHistory } from 'react-router-dom';

function PageTitle({ title, subTitle, children, hideDivider, showBackIcon }) {
  const styles = useStyles({ showBackIcon, hideDivider });
  const history = useHistory();

  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <div>
      <Box className={styles.pageTitleContainer}>
        {showBackIcon && (
          <Box className={styles.backIconContainer}>
            <CustomButton
              boxShadow='none'
              size='sm'
              variant='text'
              color='tertiary'
              style={{ minWidth: 'fit-content' }}
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowLeftIcon
                width='20'
                height='20'
                fill={palette.button.tertiary_fg}
              />
            </CustomButton>
          </Box>
        )}
        <section className={clsx(styles.pageTitleWrapper)}>
          <div>
            <Typography
              className={styles.titleStyles}
              variant='h4'
              component='h1'
              gutterBottom
            >
              {title}
            </Typography>
            <Typography className={styles.subtitleStyles} variant='subtitle2'>
              {subTitle}
            </Typography>
          </div>
          <div className={clsx(styles.pageTitleChildren)}>{children}</div>
        </section>
      </Box>
      {!hideDivider && <Divider className={clsx(styles.divider)} light />}
    </div>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.node,
  hideDivider: PropTypes.bool,
  showBackIcon: PropTypes.bool,
};

PageTitle.defaultProps = {
  title: 'Title',
  subTitle: 'Sub Title',
  children: null,
  hideDivider: false,
  showBackIcon: false,
};

export default PageTitle;
