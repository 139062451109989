import React from "react";
import clsx from "clsx";
import moment from "moment";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  CustomTimeline,
  CustomCopyToClipboard,
} from "../../../../../../../Components";
import { useSelectedTheme } from "../../../../../../../Hooks";

// Icons
import { CornerUpLeft } from "../../../../../../../assets/icons";

// Styles
const useStyles = makeStyles((theme) => ({
  timelineContainer: {
    marginTop: "16px",
  },
  timelineContentWrapper: {
    paddingInlineStart: "20px",
    paddingBottom: "24px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
      gap: "20px",
    }
  },
  infoSection: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  infoHeading: {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: theme.palette.text.primary,
  },
  infoItem: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  infoValue: {
    fontWeight: 600,
  },
  open: {
    color: theme.palette.text.success_primary,
    fontWeight: 500,
    lineHeight: "20px",
    fontSize: "14px",
  },
  close: {
    color: theme.palette.text.error_primary,
    fontWeight: 500,
    lineHeight: "20px",
    fontSize: "14px",
  },
  dateSection: {
    display: "flex",
    gap: "10px",
  },
  dateAndTime: {
    display: "flex",
    gap: "10px",
    color: theme.palette.text.text_quarterary,
  },
  dateDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    [theme.breakpoints.down('xs')]: {
      alignItems: "flex-start",
    }
  },
  date: {
    fontSize: "14px",
    fontWeight: 500,
  },
  cornerUpLeftIcon: {
    marginTop: "18px",
  },
}));

function UserActivityTimeline({}) {
  const styles = useStyles();

  const timelineItems = [
    { title: "First Title", subTitle: "Second Title", content: "Content 1" },
    // { title: "First Title", subTitle: "Second Title", content: "Content 2" },
    // { title: "First Title", subTitle: "Second Title", content: "Content 3" },
  ];

  return (
    <CustomTimeline
      timelineData={timelineItems}
      component={TimelineItemContent}
      timelineDotTopMargin="5px"
      timelineContainerClasses={styles.timelineContainer}
    />
  );
}

export default UserActivityTimeline;

// Custom Timeline Item Component
function TimelineItemContent({ item }) {
  const styles = useStyles();

  const date = "2024-09-08T12:33:30.0583283";
  
  const formattedDate = date
    ? moment(date).locale("en").format("D MMM YYYY")
    : null;
  const formattedTime = date
    ? moment(date).locale("en").format("h:mm a")
    : null;

  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <Box className={styles.timelineContentWrapper}>
      <Box className={styles.infoSection}>
        <Typography className={styles.infoHeading} component="h3">
          New lead added - CRM
        </Typography>
        <Typography
          className={styles.infoItem}
          variant="body1"
          component="span"
        >
          Lead Name:{" "}
          <strong className={styles.infoValue}>Wessam Lahloub</strong>
        </Typography>
        <Typography
          className={styles.infoItem}
          variant="body1"
          component="span"
        >
          Lead id:
          <CustomCopyToClipboard
            data={333} // copy
            childrenData={"#" + 4444443} // render
            copyDoneShowTime={1000}
          />
        </Typography>
        <Typography
          className={clsx(styles.infoItem, false ? styles.open : styles.close)}
          variant="body1"
          component="span"
        >
          Closed
        </Typography>
      </Box>
      <Box className={styles.dateSection}>
        <Box className={styles.dateDetails}>
          <Box className={styles.dateAndTime}>
            {date && (
              <Typography className={styles.date}>{formattedDate}</Typography>
            )}
            {date && (
              <Typography className={styles.date}>{formattedTime}</Typography>
            )}
          </Box>
          <Typography
            className={styles.infoItem}
            variant="body1"
            component="span"
          >
            Assign to: <strong className={styles.infoValue}>Olivia Rhye</strong>
          </Typography>
          <Typography
            className={styles.infoItem}
            variant="body1"
            component="span"
          >
            Created by:{" "}
            <strong className={styles.infoValue}>Olivia Rhye</strong>
          </Typography>
        </Box>
        <CornerUpLeft
          width="24"
          height="24"
          fill={palette.foreground.quarterary}
          className={styles.cornerUpLeftIcon}
        />
      </Box>
    </Box>
  );
}
