import React, {
  Children,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import './LeadsStagesBreadcrumb.scss';
import {
  CheckLeadStageMandatoryActivities,
  GetActivityTypeById,
  GetLookupItemsByLookupTypeName,
  OrganizationUserSearch,
  UpdateLeadStage,
} from '../../Services';
import { showError } from '../../Helper';
import PropTypes from 'prop-types';
import { DialogComponent } from '../DialogComponent/DialogComponent';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../SpinnerComponent/Spinner';
import OpportunityLeadStageDialog from '../../Views/Home/LeadsSalesView/LeadsSalesProfileManagementView/Sections/LeadInformationComponent/OpportunityLeadStageDialog';
import { AutocompleteComponent, CheckboxesComponent } from '../Controls';
import QualifyLeadDialog from './Components/QualifyLeadDialog';
import CorporateDocumentsDialog from './Components/CorporateDocumentsDialog';

const ConverterData = (data) => {
  return data
    .filter((item) => item.assignedToId)
    .map((item) => ({
      activityTypeId: item.activityTypeId,
      activityTypeName: item.activityTypeName,
      assignedToId: item.assignedToId,
    }));
};

export const LeadsStagesBreadcrumb = ({
  currentStage,
  setActiveTab,
  reload,
  leadId,
  updateStageId,
  clearUpdateStageId,
  contactId,
  translationPath,
  parentTranslationPath,
  contactType,
  isDisabled,
}) => {
  const { t } = useTranslation('LeadsStage');
  const [data, setData] = useState([]);
  const [dataActivityAssignments, setDataActivityAssignments] = useState([]);
  const [missingActivityDto, setMissingActivityDto] = useState([]);
  const searchTimer = useRef(null);
  const [filter] = useState({ pageIndex: 0, pageSize: 25 });
  const [roles, setRoles] = useState(null);
  const [saveIsDisabled, setSaveIsDisabled] = useState(true);
  const [isOpenQualifyLeadDialog, setIsOpenQualifyLeadDialog] = useState({
    open: false,
    stageId: null,
  });
  const CurrentUser = JSON.parse(localStorage.getItem('session')) || null;

  const getAllActivityAssignments = useCallback(
    async (value, assignToUserRoles) => {
      //setLoadings({ id: 'activityAssignments', value: true });
      const res = await OrganizationUserSearch({
        ...filter,
        rolesIds: assignToUserRoles || roles,
        name: (value && value.value) || '',
        userStatusId: 2,
      });
      if (!(res && res.status && res.status !== 200)) {
        const localValue = (res && res.result) || [];
        if (localValue.length > 0) {
          setDataActivityAssignments(localValue);
        } else {
          setDataActivityAssignments([]);
        }

        //setLoadings({ id: 'activityAssignments', value: false });
      }
    },
    [filter, roles]
  );

  const getActivityTypeByIdApi = useCallback(
    async (activityTypeId, onSearch) => {
      if (activityTypeId !== null) {
        const res = await GetActivityTypeById(activityTypeId);
        if (!(res && res.status && res.status !== 200)) {
          const assignToUserRoles = res.assignedToActivityTypeRoles
            ? res.assignedToActivityTypeRoles.map((item) => item.rolesId)
            : [];
          setRoles(assignToUserRoles);
          getAllActivityAssignments(onSearch, assignToUserRoles);
        }
      }
    },
    []
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState({
    open: false,
    isOpportunityStage: false,
    isQualifyLeadStage: false,
  });
  const [isOpportunityStage, setIsOpportunityStage] = useState({
    open: false,
    stageId: null,
  });

  const [targetedStageId, setTargetedStageId] = useState(null);

  const getLeadStages = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'LeadStage',
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res?.status && res.status !== 200)) {
      setData(res?.result);
    }
  };

  const scrollRef = useRef();

  const updateLeadStage = async ({ leadId, leadStageId }) => {
    setIsLoading(true);
    try {
      const res = await UpdateLeadStage({
        leadId,
        leadStageId,
        updateLeadStageActivityDetailsDto: ConverterData(missingActivityDto),
      });
      if (res) {
        setIsDialogOpen({
          open: false,
          isOpportunityStage: false,
          isQualifyLeadStage: false,
        });
        if (clearUpdateStageId) {
          clearUpdateStageId();
        }
        reload();
      } else {
        showError(t('update-error'));
      }
    } catch (error) {
      showError(t('update-error'));
    } finally {
      setIsLoading(false);
    }
  };

  const clickHandler = async (leadStageId, index) => {
    const currentStageIndex = data.findIndex(
      (stage) => stage.lookupItemId === currentStage
    );
    if (index <= currentStageIndex || data.length - 1 === index) {
      return;
    }
    setTargetedStageId(leadStageId);
    const isOpportunityStage =
      data.find((stage) => stage.lookupItemId === leadStageId)
        ?.lookupItemName === 'Opportunity';

    const isQualifyLeadStage =
      data.find((stage) => stage.lookupItemId === leadStageId)
        ?.lookupItemName === 'Qualified lead';

    const res = await CheckLeadStageMandatoryActivities({
      leadStageId: currentStage,
      leadId,
    });
    if (res?.[0]?.missingActivityDto.length > 0) {
      setMissingActivityDto(res[0].missingActivityDto);
      setIsDialogOpen({ open: true, isOpportunityStage, isQualifyLeadStage });
    } else if (
      res?.[0]?.missingActivityDto.length === 0 &&
      isOpportunityStage
      // &&
      // contactType === 1
    ) {
      setIsOpportunityStage({
        open: true,
        stageId: leadStageId,
      });
    } else if (
      res?.[0]?.missingActivityDto.length === 0 &&
      isQualifyLeadStage &&
      contactType === 1
    ) {
      setIsOpenQualifyLeadDialog({
        open: true,
        stageId: leadStageId,
      });
    } else if (
      res?.[0]?.missingActivityDto.length === 0 &&
      (!isOpportunityStage || !isQualifyLeadStage)
    ) {
      await updateLeadStage({ leadId, leadStageId });
      clearUpdateStageId();
      reload();
    }
  };

  const saveHandler = async (e) => {
    e.preventDefault();
    if (
      isDialogOpen.isOpportunityStage
      //  && contactType === 1
    ) {
      setIsDialogOpen({ open: false, isOpportunityStage: false });
      setIsOpportunityStage({
        open: true,
        stageId: targetedStageId,
      });
    } else if (isDialogOpen.isQualifyLeadStage && contactType === 1) {
      setIsDialogOpen({ open: false, isQualifyLeadStage: false });
      setIsOpenQualifyLeadDialog({
        open: true,
        stageId: targetedStageId,
      });
    } else {
      await updateLeadStage({
        leadId,
        leadStageId: targetedStageId,
      });
    }
  };

  useEffect(() => {
    getLeadStages();
  }, []);

  useEffect(() => {
    if (updateStageId && !isDisabled) {
      clickHandler(
        updateStageId,
        data.findIndex((stage) => stage.lookupItemId === updateStageId)
      );
    }
  }, [updateStageId, data, isDisabled]);

  const opportunityDialogCloseClicked = () => {
    setIsOpportunityStage({ open: false });
  };
  const qualifyLeadDialogCloseClicked = () => {
    setIsOpenQualifyLeadDialog({ open: false });
  };

  useEffect(() => {
    const index = data.findIndex((item) => item.lookupItemId === currentStage);
    if (index !== -1) {
      const width = scrollRef.current.scrollWidth;
      scrollRef.current.scrollLeft = width * (index / data.length);
    }
  }, [currentStage, data]);

  useEffect(() => {
    const hasValidAssignedToId = missingActivityDto.some(
      (activity) =>
        (activity?.isMissingNewValue &&
          activity?.assignedToId &&
          activity?.assignedToId !== null &&
          activity?.assignedToId?.length > 0) ||
        (activity.isMissing === false && !activity.assignedToId)
    );
    const hasNullAssignedToId = missingActivityDto.some(
      (activity) =>
        activity?.isMissingNewValue && activity?.assignedToId === null
    );
    setSaveIsDisabled(hasNullAssignedToId || !hasValidAssignedToId);
  }, [missingActivityDto]);

  return (
    <>
      <Spinner isActive={isLoading} isAbsolute />
      <ul className='stages-breadcrumb v1' ref={scrollRef}>
        {Children.toArray(
          data.map((item, index) => {
            const currentStageIndex = data.findIndex(
              (stage) => stage.lookupItemId === currentStage
            );
            const isPastOrCurrentStage = index <= currentStageIndex;
            return (
              <li
                className={`breadcrumb-level ${
                  isPastOrCurrentStage ? 'past-stage' : ''
                } ${data.length - 1 === index ? 'not-allowed' : ''}`}
              >
                <a
                  className={item.lookupItemId === currentStage ? 'active' : ''}
                  onClick={() => {
                    if (!isDisabled) clickHandler(item.lookupItemId, index);
                  }}
                >
                  {item.lookupItemName}
                </a>
              </li>
            );
          })
        )}
      </ul>
      <DialogComponent
        titleText={t('missing-stage-activity')}
        isOpen={isDialogOpen.open}
        onCloseClicked={() => {
          setIsDialogOpen(false);
          clearUpdateStageId();
        }}
        onCancelClicked={() => {
          setIsDialogOpen(false);
          if (setActiveTab) {
            setActiveTab(1);
          }
          if (clearUpdateStageId) {
            clearUpdateStageId();
          }
        }}
        onSaveClicked={saveHandler}
        maxWidth={'sm'}
        cancelText={t('fill-activities')}
        saveText={t('update-lead')}
        saveIsDisabled={saveIsDisabled}
        dialogContent={
          <div>
            <h6>{t('missing-stage-activity')}</h6>
            <div className='stages-list'>
              {missingActivityDto.map((item) => (
                <div key={item.activityTypeId} className=''>
                  {(!item.isMissing && (
                    <div className='isMissing'>
                      <CheckboxesComponent
                        label={item.activityTypeName}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        disabled={true}
                        singleChecked={true}
                      />
                    </div>
                  )) ||
                    (!item.canUserCreate && (
                      <div className='isMissing'>
                        <CheckboxesComponent
                          label={item.activityTypeName}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          disabled={true}
                          singleChecked={item.canUserCreate}
                        />
                      </div>
                    )) || (
                      <div className='MissingStageActivity'>
                        <CheckboxesComponent
                          label={item.activityTypeName}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          disabled={false}
                          singleChecked={item.isMissingNewValue}
                          onSelectedCheckboxClicked={(e) => {
                            setMissingActivityDto((prev) =>
                              prev.map((activity) =>
                                activity.activityTypeId === item.activityTypeId
                                  ? {
                                      ...activity,
                                      isMissingNewValue: e.target.checked,
                                      assignedTo:
                                        item.canAssignToCurrentUser === true
                                          ? {
                                              fullName: CurrentUser.fullName,
                                              id: CurrentUser.userId,
                                            }
                                          : null,
                                      assignedToId:
                                        item.canAssignToCurrentUser === true
                                          ? CurrentUser.userId
                                          : null,
                                    }
                                  : activity
                              )
                            );
                            if (e.target.checked === false) {
                              setMissingActivityDto((prev) =>
                                prev.map((activity) =>
                                  activity.activityTypeId ===
                                  item.activityTypeId
                                    ? {
                                        ...activity,
                                        assignedToId: null,
                                        assignedTo: null,
                                      }
                                    : activity
                                )
                              );
                            }
                          }}
                        />
                        {item.isMissingNewValue && (
                          <div className='assignedToActivityClass'>
                            <AutocompleteComponent
                              idRef='assdFtIdRef'
                              labelValue='activity-assign-to'
                              labelClasses='Requierd-Color'
                              inputPlaceholder={t(
                                'Type-here-to-Search-in-Users'
                              )}
                              multiple={false}
                              data={dataActivityAssignments || []}
                              displayLabel={(option) => option.fullName || ''}
                              renderOption={(option) =>
                                ((option.userName || option.fullName) &&
                                  `${option.fullName} (${option.userName})`) ||
                                ''
                              }
                              withoutSearchButton
                              onInputKeyUp={(e) => {
                                const onSearch = e.target;
                                if (searchTimer.current)
                                  clearTimeout(searchTimer.current);
                                searchTimer.current = setTimeout(() => {
                                  getActivityTypeByIdApi(
                                    item.activityTypeId,
                                    onSearch
                                  );
                                }, 700);
                              }}
                              parentTranslationPath={parentTranslationPath}
                              translationPath={translationPath}
                              selectedValues={item && item.assignedTo}
                              getOptionSelected={(option) =>
                                option &&
                                option.id === item &&
                                item.assignedToId
                              }
                              onChange={(event, newValue) => {
                                setMissingActivityDto((prev) =>
                                  prev.map((activity) =>
                                    activity.activityTypeId ===
                                    item.activityTypeId
                                      ? {
                                          ...activity,
                                          assignedToId: newValue?.id,
                                          assignedTo: newValue,
                                        }
                                      : activity
                                  )
                                );
                                setDataActivityAssignments([]);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                </div>
              ))}
            </div>
            <p className='confirm-message'>{t('confirm-message')}</p>
          </div>
        }
      />
      {isOpportunityStage.open && (
        <DialogComponent
          titleText={t('opportunity-lead-stage', {
            ns: 'LeadAssignAgentsTracking',
          })}
          isOpen={isOpportunityStage.open}
          onCloseClicked={() =>
            setIsOpportunityStage({ open: false, stageId: null })
          }
          dialogContent={
            contactType === 1 ? (
              <OpportunityLeadStageDialog
                opportunityLeadStageDialogIsOpen
                onClose={opportunityDialogCloseClicked}
                stageId={isOpportunityStage.stageId}
                contactId={contactId}
                reload={reload}
                leadId={leadId}
              />
            ) : (
              <CorporateDocumentsDialog
                contactId={contactId}
                reload={reload}
                leadId={leadId}
                stageId={isOpportunityStage.stageId}
                onClose={opportunityDialogCloseClicked}
                onSave={() =>
                  updateLeadStage({ leadId, leadStageId: targetedStageId })
                }
              />
            )
          }
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {isOpenQualifyLeadDialog.open && (
        <QualifyLeadDialog
          isOpen={isOpenQualifyLeadDialog.open}
          onClose={qualifyLeadDialogCloseClicked}
          setIsOpenQualifyLeadDialog={setIsOpenQualifyLeadDialog}
          reload={reload}
          updateLeadStage={(e) =>
            updateLeadStage({
              leadId,
              leadStageId: targetedStageId,
            })
          }
        />
      )}
    </>
  );
};

LeadsStagesBreadcrumb.propTypes = {
  activeData: PropTypes.instanceOf(Object),
  clearUpdateStageId: PropTypes.func,
  isDisabled: PropTypes.bool,
  onActionClicked: PropTypes.func,
  parentTranslationPath: PropTypes.string,
};
LeadsStagesBreadcrumb.defaultProps = {
  activeData: null,
  isDisabled: false,
  parentTranslationPath: 'LeadsProfileManagementView',
  clearUpdateStageId: () => {},
  onActionClicked: () => {},
};
