import React, { useState, useCallback, useEffect, useRef, useMemo, Children } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Button, ButtonBase } from "@material-ui/core";
import moment from "moment";
import {
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
  GlobalHistory,
  bottomBoxComponentUpdate,
  havePermissionToViewDetails,
  havePermissionToEditInformation,
  getSideMenuStatus,
  getSideMenuComponent,
  returnPropsByPermissions,
} from "../../../Helper";
import {
  ActionsButtonsComponent,
  ViewTypes,
  Spinner,
  PermissionsComponent,
  DialogComponent,
  SelectComponet,
} from "../../../Components";
import {
  ActionsEnum,
  ViewTypesEnum,
  TableActions,
  ActionsButtonsEnum,
  FormsIdsEnum,
  TableFilterTypesEnum,
  TableFilterOperatorsEnum,
  ArrayOFSearchableFormFields,
  AgentRoleEnum,
  ListingAgentIdsEnum,
  DateFilterTypesEnum,
  SearchCriteriaEnum,
} from "../../../Enums";
import {
  CardDetailsComponent,
  UnitsCardsComponent,
  UnitsSalesTableComponent,
} from "../UnitsSalesView/UnitsSalesUtilities";
import {
  UnitsAdvanceSearchTest,
  GetAllSearchableFormFieldsByFormId,
  GetAllFormFieldsByFormId,
  GetLookupItems,
  GetUnitDetails,
  GetAllSystemTemplateByCategoryId,
  PropertiesAdvanceSearchByUnits,
} from "../../../Services";
import { AdvanceSearch } from "../../../SharedComponents/AdvanceSearch/AdvanceSearch";
import { AdvanceSearchSecondVersion } from "../../../SharedComponents/AdvanceSearchSecondVersion";
import { PaginationComponent } from "../../../Components/PaginationComponent/PaginationComponent";
import { UnitMapper } from "../UnitsSalesView/UnitMapper";
import { ActiveItemActions } from "../../../store/ActiveItem/ActiveItemActions";
import { useTitle } from "../../../Hooks";
import { GlobalOrderFilterActions } from "../../../store/GlobalOrderFilter/GlobalOrderFilterActions";
import { GlobalAdvanceSearchActions } from "../../../store/GlobalAdvanceSearch/GlobalAdvanceSearchActions";
import { PrimaryUnitsPermissions } from "../../../Permissions";
import { UnitsSalesTableHeaderData } from "../UnitsSalesView/UnitsSalesUtilities/UnitsSalesTableComponent/UnitsSalesTableHeaderData";
import { UnitsVerticalTabsData } from "../Common/OpenFileView/OpenFileUtilities/OpenFileData/UnitsVerticalTabsData";
import { ActivitiesManagementDialog } from "../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog";
import { useScrollToElement } from "../../../Hooks/useScrollToElement";
import { useSuccessDialog } from "../../../SharedComponents";
import { OwnerDetailsDialog } from "../UnitsSalesView/UnitsSalesUtilities/Dialogs";
import { PrimaryUnitsFilterComponent } from "./PrimaryUnitsFilterComponent";
import { UnitTemplateDialogPreviewDialog } from "../UnitsView/UnitsProfileManagementView/Dialogs/UnitTemplateDialog/UnitTemplateDialogPreviewDialog";
import UnitsMapView from "../../../SharedComponents/UnitsMapView";
import UnitsByProperty from "../UnitsSalesView/UnitsSalesUtilities/UnitsByProperty";
import UnitCard from "../UnitsSalesView/UnitsSalesUtilities/UnitsByProperty/UnitCard";

const parentTranslationPath = "UnitsView";
const translationPath = "";

export const PrimaryJourneyView = () => {
  const { t } = useTranslation(parentTranslationPath);

  const [ownerDetailsDialog, setOwnerDetailsDialog] = useState({
    isOpen: false,
    titleText: "",
  });

  const [leadOwnerDetails, setLeadOwnerDetails] = useState({});
  const { isOpen } = useSuccessDialog();
  const [addActivity, setAddActivity] = useState(false);
  const [isProposalDialogOpen, setIsProposalDialogOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [displayOpenFileButton, setDisplayOpenFileButton] = useState(false);
  const [displayMatchingButton, setDisplayMatchingButton] = useState(false);
  const dispatch = useDispatch();

  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const advanceSearch = useSelector(
    (state) => state.GlobalAdvanceSearchReducer
  );
  const [unitsTableFilter, setUnitsTableFilter] = useState(
    (advanceSearch && advanceSearch.UnitsSalesFilter) || null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isAllFormFieldsLoading, setIsAllFormFieldsLoading] = useState(false);
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [activeActionType, setActiveActionType] = useState(
    (localStorage.getItem("ViewType") &&
      JSON.parse(localStorage.getItem("ViewType")).unitsSaleView) ||
      ViewTypesEnum.cards.key
  );

  const [isExpanded, setIsExpanded] = useState(
    activeActionType === ViewTypesEnum.cardsExpanded.key
  );
  const [activeSelectedAction, setActiveSelectedAction] = useState(ActionsButtonsEnum[16].id);
  const [unitsPortal, setUnitsPortal] = useState(
    orderFilter.PublishSalePortal || [0]
  );
  const [activeCard, setActiveCard] = useState(null);
  const [advanceSearchBtn, setAdvanceSearchBtn] = useState(true);
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [isAdvanceSearchCleared, setIsAdvanceSearchCleared] = useState(false);
  const [isAdvanceSearchActive, setIsAdvanceSearchActive] = useState(false);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: orderFilter?.unitsSalesFilter?.filterBy,
    orderBy: orderFilter?.unitsSalesFilter?.orderBy,
  });

  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy
      ? selectedOrderBy
      : { filterBy: "createdOn", orderBy: 2 }
  );
  const [checkedDetailedCards, setCheckedDetailedCards] = useState([]);
  const [detailsUnitsList, setDetailsUnitsList] = useState(() => ({
    result: [],
    totalCount: 0,
  }));
  const { setHash } = useScrollToElement(!!detailsUnitsList.result.length);
  const [allFormFields, setAllFormFields] = useState([]);
  const [unitsCardsByProperty, setUnitCardsByProperty] =  useState(false)
  const [selectedUnitsCards, setSelectedUnitsCards] = useState([])
  const [isSelectedUnitDialogOpen, setIsSelectedUnitDialogOpen] = useState(false);

  const [searchData, setSearchData] = useState([]);
  const [filterSearchDto, setFilterSearchDto] = useState(null);
  const [checkedCards, setCheckedCards] = useState([]);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    pageIndex:
      JSON.parse(localStorage.getItem("unitsSalesCurrentPageIndex")) || 0,
    search: "",
  });
  const [isFirst, setFirst] = useState(false);
  const [isFirst1, setFirst1] = useState(false);
  const [unitPublishStatus, setUnitPublishStatus] = useState(
    orderFilter.UnitSalesPublishStatusFilter || 0
  );

  const [selectFieldsOnAdvanceSearch, setSelectFieldsOnAdvanceSearch] =
    useState([]);
  const [filterValuesFromAdvanceSearch, setFilterValuesFromAdvanceSearch] =
    useState(null);
  useTitle(t(`${translationPath}units`));

  const isMapView = useMemo(
    () => activeActionType === ViewTypesEnum.map.key,
    [activeActionType]
  );

  useTitle(t(`${translationPath}units`));

  const onTypeChanged = useCallback(
    (activeType) => {
      const ViewTypeData = JSON.parse(localStorage.getItem("ViewType"));
      if (ViewTypeData) {
        ViewTypeData.unitsSaleView = activeType;
        localStorage.setItem("ViewType", JSON.stringify(ViewTypeData));
      } else
        localStorage.setItem(
          "ViewType",
          JSON.stringify({ unitsSaleView: activeType })
        );

      setActiveActionType(activeType);
      setIsExpanded(activeType === ViewTypesEnum.cardsExpanded.key);
      sideMenuIsOpenUpdate(false);
      sideMenuComponentUpdate(null);
    },
    [setActiveActionType]
  );
  
  const onPageIndexChanged = (pageIndex) => {
    localStorage.setItem(
      "unitsSalesCurrentPageIndex",
      JSON.stringify(pageIndex)
    );
    setFilter((item) => ({ ...item, pageIndex }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const [templatesProposal, setTemplatesProposal] = useState([]);
  const [activeTemplateId, setActiveTemplateId] = useState(null);
  const [detailsPropertyByUnitsList, setDetailsPropertyByUnitsList] = useState(() => ({
    result: [],
    totalCount: 0,
  }));
  const [map, setMap] = useState(undefined);
  const [isMapChanged, setIsMapChanged] = useState(false);
  const [stopChangeCapturing, setStopChangeCapturing]= useState(false)
  const [mapKey, setMapKey] = useState(new Date().toString())
  const [selectedPropertyForMap, setSelectedPropertyForMap] = useState(null)

  const getAllSystemTemplateByCategoryId = useCallback(async () => {
    const res = await GetAllSystemTemplateByCategoryId(8);
    if (!(res && res.status && res.status !== 200)) {
      const mappedTemplatesData =
        res &&
        res.map((item) => ({
          systemTemplateName: item.systemTemplateName,
          systemTemplateId: item.systemTemplateId,
        }));
      setTemplatesProposal(mappedTemplatesData || []);
    } else setTemplatesProposal([]);
  }, [filter]);

  const restorePageIndex = useCallback(() => {
    const currentPageIndex = localStorage.getItem("unitsSalesCurrentPageIndex");

    if (currentPageIndex) {
      const pageIndex = JSON.parse(currentPageIndex);
      setFilter((item) => ({ ...item, pageIndex }));
    }
  }, []);

  useEffect(() => {
    restorePageIndex();
  }, []);

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };

  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsAllFormFieldsLoading(true);
    const result = await GetAllFormFieldsByFormId(FormsIdsEnum.units.id);
    if (
      !((result && result.data && result.data.ErrorId) || !result) &&
      Array.isArray(result)
    ) {
      const list = result
        .filter(
          (e) =>
            e.formFieldId !==
              ListingAgentIdsEnum.RentListingAgent.formFieldId &&
            e.formFieldName !== "amenities" &&
            e.formFieldName !== "fitting_and_fixtures" &&
            e.formFieldName !== "unit_model" &&
            e.formFieldName !== "rent_price_fees" &&
            e.formFieldName !== "selling_price_agency_fee" &&
            e.formFieldName !== "primary_view" &&
            e.formFieldName !== "secondary_view" &&
            e.formFieldName !== "owner"
        )
        .map((field) => ({
          id: field.formFieldId || null,
          key: field.formFieldKey || null,
          isDate: field.uiWidgetType === "alt-date" || false,
          label:
            (field.formFieldTitle === "Lead Owner"
              ? (field.formFieldTitle = "Unit Owner")
              : field.formFieldTitle &&
                field.formFieldTitle.replace("*", "")) || "",
          input: field.displayPath || "",
          isNumber:
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey ===
                "currency") ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey === "decimal") ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey === "size"),
          isSortable: true,
          searchableKey: field.searchableKey,
          data: JSON.parse(field.propertyJson).schema,
          fieldType:
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema.uiType) ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).uiSchema["ui:widget"]) ||
            (field.uiWidgetType === null && "text"),
        }));

      setAllFormFields(list);
    } else setAllFormFields([]);
    setIsAllFormFieldsLoading(false);
  }, []);

  const searchAdvanceSearch = (newFilter) => {
    const searchObj = newFilter ? { ...newFilter } : null;
    if (!(newFilter && newFilter.length))
      localStorage.removeItem("UnitsSalesFilter");

    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        UnitsSalesFilter: searchObj || null,
      })
    );
    setUnitsTableFilter(searchObj);
  };
  const GetSideMenuStatus = useCallback(() => {
    const isMenuOpen = getSideMenuStatus();
    const activeComponentOpen =
      getSideMenuComponent() && getSideMenuComponent().type;

    if (!isMenuOpen) setAdvanceSearchBtn(true);
    else if (activeComponentOpen === AdvanceSearch) setAdvanceSearchBtn(false);
  });

  const onAdvanceSearchClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (advanceSearchBtn) {
        sideMenuIsOpenUpdate(true);
        setAdvanceSearchBtn(false);
      } else {
        sideMenuIsOpenUpdate(false);
        setAdvanceSearchBtn(true);
      }

      sideMenuComponentUpdate(
        <AdvanceSearchSecondVersion
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onFilterValuesChanged={onFilterValuesChanged}
          localStorageKey="UnitsSalesFilter"
          isAdvanceSearchCleared={isAdvanceSearchCleared}
          setIsAdvanceSearchCleared={setIsAdvanceSearchCleared}
          setOrderBy={setOrderBy}
          searchCriteriaTypeId={SearchCriteriaEnum.Unit.typeId}
          setIsAdvanceSearchActive={setIsAdvanceSearchActive}
          fields={[...UnitsSalesTableHeaderData, ...allFormFields]}
          selectFieldsOnAdvanceSearch={selectFieldsOnAdvanceSearch}
          setSelectFieldsOnAdvanceSearch={setSelectFieldsOnAdvanceSearch}
          setFilterValuesFromAdvanceSearch={setFilterValuesFromAdvanceSearch}
          filterValuesFromAdvanceSearch={filterValuesFromAdvanceSearch}
          searchAdvanceSearch={searchAdvanceSearch}
          unitType={"sale"}
        />
      );
    },
    [
      isAllFormFieldsLoading,
      allFormFields,
      advanceSearchBtn,
      isAdvanceSearchCleared,
      selectFieldsOnAdvanceSearch,
    ]
  );

  const onClearedAllFiltersClick = useCallback(() => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: null, orderBy: null }));
    setOrderBy({ filterBy: "createdOn", orderBy: 2 });
    setSearchData([]);
    setUnitPublishStatus(0);
    setUnitsPortal([]);
    setIsClearFiltersClicked(true);
    setIsAdvanceSearchCleared(true);
    setUnitsTableFilter(null);
    setFilterSearchDto(null);
    sideMenuIsOpenUpdate(false);
    setIsAdvanceSearchActive(false);
    setFilterValuesFromAdvanceSearch();
    setSelectFieldsOnAdvanceSearch([]);
    setMapKey(new Date().toString())
    setSelectedPropertyForMap(null);

    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        UnitsSalesStatus: [],
        PublishSalePortal: [],
        UnitSalesPublishStatusFilter: 0,
        unitsSalesFilter: {
          filterBy: null,
          orderBy: null,
        },
      })
    );

    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        UnitsSalesFilter: null,
      })
    );
  }, []);

  const getUnitsData = useCallback(
    async (f, dateFilter) => {
      if (isLoading && isMapView) {
        return;
      }
      if (isMapView && !map) {
        return;
      }
      if (
        returnPropsByPermissions(
          PrimaryUnitsPermissions.ViewPrimaryUnits.permissionsId
        )
      ) {
        setIsLoading(true);

        let body = {};

        const indexPortal = unitsPortal.findIndex((el) => el === 0);
        if (indexPortal !== -1) unitsPortal.splice(indexPortal, 1);
        if (unitsPortal.length === 0) unitsPortal.push(0);

        const localFilterDto = f || filterSearchDto || {};

        const filterCreatedByRole = loginResponse.roles.find(
          (role) => role.rolesName === "Filter Created By"
        );
        const filterListingAgentRole = loginResponse.roles.find(
          (role) => role.rolesName === "Filter Listing Agent"
        );
        const filterDubaiTeamRole = loginResponse.roles.find(
          (role) => role.rolesName === "Filter Dubai Team"
        );
        if (filterCreatedByRole) {
          localFilterDto.createdById = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: loginResponse.userId,
            },
          ];
        }
        if (filterListingAgentRole) {
          localFilterDto.listing_agent_id = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: loginResponse.userId,
            },
          ];
        }
        if (filterDubaiTeamRole) {
          localFilterDto.city = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: "dubai",
            },
          ];
        }

        if (unitsTableFilter) {
          Object.values(unitsTableFilter)
            .filter((item) => item.searchableKey || item.displayPath)
            .map((item) => {
              if (localFilterDto[item.searchableKey || item.displayPath]) {
                localFilterDto[item.searchableKey || item.displayPath].push({
                  searchType: item.operator,
                  value: item.value,
                });
              } else if (item.value) {
                localFilterDto[item.searchableKey || item.displayPath] = [
                  {
                    searchType: item.operator,
                    value: item.value,
                  },
                ];
              } else if (
                !item.value &&
                (item.operator === TableFilterOperatorsEnum.isNotBlank.key ||
                  item.operator === TableFilterOperatorsEnum.isBlank.key)
              ) {
                localFilterDto[item.searchableKey || item.displayPath] = [
                  {
                    searchType: item.operator,
                    value: null,
                  },
                ];
              }
              return undefined;
            });
        }

        if (Object.values(localFilterDto).length)
          setIsAdvanceSearchActive(true);
        else setIsAdvanceSearchActive(false);

        setIsDatePickerChanged(false);
        setIsClearFiltersClicked(false);

        delete localFilterDto["handover_date_from"];
        delete localFilterDto["handover_date_to"];

        if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
          const fromDate = moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss");
          const toDate = moment(dateFilter.endDate).format(
            "YYYY-MM-DDTHH:mm:ss"
          );

          if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.CreatedOnDate.key
          ) {
            body.fromDate = fromDate;
            body.toDate = toDate;
          } else if (
            dateFilter.selectedDateType === DateFilterTypesEnum.HandoverDate.key
          ) {
            localFilterDto["handover_date_from"] = [
              {
                searchType: 5,
                value: fromDate,
              },
            ];

            localFilterDto["handover_date_to"] = [
              {
                searchType: 6,
                value: toDate,
              },
            ];
          }
        }

        body = {
          ...body,
          criteria: localFilterDto,
          ...orderBy,
          OperationType: "Sale",
          isPrimaryJourney: true,
        };

        const propertyBody = {...body, map}

        if (isMapView && (!unitsCardsByProperty || isMapChanged || !advanceSearchBtn)) {
          if(!!Object.keys(propertyBody.criteria).length){
            delete propertyBody.map;
            setStopChangeCapturing(true)
          } else {
            setStopChangeCapturing(false)
          }
        }

        if(!body.criteria.property_name && selectedPropertyForMap){
          body.criteria.property_name = [{value:selectedPropertyForMap.value, searchType: 1}];
        } 


        const res = isMapView && (!unitsCardsByProperty || isMapChanged || !advanceSearchBtn)
        ? await PropertiesAdvanceSearchByUnits(filter, propertyBody)
        : await UnitsAdvanceSearchTest(filter, body);
        
        if (!(res && res.status && res.status !== 200) && res && res.result) {
          if (isMapView && (!unitsCardsByProperty || isMapChanged || !advanceSearchBtn)) {
            setDetailsPropertyByUnitsList({
              result: res.result,
              totalCount: res?.totalCount ?? 0,
            });
          } else {
            const result = ((res && res.result) || []).map(
              (item) =>
                item.unitJson && UnitMapper(item, JSON.parse(item.unitJson).unit)
            );
            setDetailsUnitsList({
              result: result,
              totalCount: (res && res.totalCount) || 0,
            });
          }
          setIsLoading(false);
        } else {
          setDetailsUnitsList({
            result: [],
            totalCount: 0,
          });
          setIsLoading(false);
        }
        setIsMapChanged(false)
      }
    },
    [
      filter,
      filterSearchDto,
      orderBy,
      unitsPortal,
      unitPublishStatus,
      unitsTableFilter,
      map,
      isMapChanged,
      unitsCardsByProperty,
      selectedPropertyForMap,
    ]
  );
  const searchChangedClickedWithoutFilter = async (data) => {
    if (data.length === 0) return;
    const oldFilter = data.reduce((total, item) => {
      if (total[item.key]) {
        total[item.key].push({
          searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
          value: item.value,
        });
      } else {
        total[item.key] = [
          {
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          },
        ];
      }
      return total;
    }, {});
    getUnitsData(oldFilter);
  };
  const tableActionClicked = useCallback(
    (actionEnum, item) => {
      dispatch(ActiveItemActions.activeItemRequest(item));
      if (actionEnum === TableActions.openFile.key) {
        GlobalHistory.push(
          `/home/units-sales/unit-profile-edit?formType=${item.unitTypeId}&id=${item.id}&operationType=${item.operationType}`
        );
      }
      if (actionEnum === TableActions.addActivity.key) {
        setAddActivity(true);
        setActiveItem(item);
      }
    },
    [dispatch]
  );

  const onActionButtonChanged = (activeAction) => {
    setActiveSelectedAction(ActionsButtonsEnum[16].id);
    setCheckedCards([]);
    setActiveTemplateId(null)
    setCheckedDetailedCards([])
  };
  const onActionsButtonClicked = useCallback((activeAction) => {
    if (activeAction === ActionsButtonsEnum[1].id)
      GlobalHistory.push("/home/units-sales/add?formType=1");

    if (activeAction === ActionsButtonsEnum[16].id)
      setIsProposalDialogOpen(true);
  }, []);
  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      setHash(activeData.id);
      dispatch(ActiveItemActions.activeItemRequest(activeData));
      event.stopPropagation();
      if (actionEnum === ActionsEnum.folder.key) {
        GlobalHistory.push(
          `/home/units-sales/unit-profile-edit?formType=${activeData.unitTypeId}&id=${activeData.id}&operationType=${activeData.operationType}`
        );
      } else if (actionEnum === ActionsEnum.matching.key) {
        GlobalHistory.push(
          `/home/units-sales/unit-profile-edit?formType=${
            activeData.unitTypeId
          }&id=${activeData.id}&operationType=${
            activeData.operationType
          }&matching=${true}`
        );
      }
    },
    [dispatch]
  );

  const cardCheckboxClicked = useCallback((itemIndex, element) => {
    setCheckedDetailedCards((items) => {
      const index = items.findIndex((item) => item === element.id);
      if (index !== -1) items.splice(index, 1);
      else items.push(element.id);
      return [...items];
    });
    setCheckedCards((items) => {
      const index = items.findIndex((item) => item.id === element.id);
      if (index !== -1) items.splice(index, 1);
      else items.push(element);
      return [...items];
    });
  }, []);
  useEffect(() => {
    localStorage.setItem("bulk-assign-ids", JSON.stringify(checkedCards));
  }, [checkedCards]);
  const reloadData = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: 0 }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  }, [getUnitsData]);

  const getUnitDetails = async (unitId) => {
    try {
      const res = await GetUnitDetails(unitId);
      setLeadOwnerDetails(res.leadOwner);
      return res.leadOwner;
    } catch (error) {
      console.error("Error fetching unit details:", error);
    }
  };

  const onCardClick = useCallback(
    (item, selectedIndex) => async (event) => {
      event.stopPropagation();
      event.preventDefault();
      const selectedLeadOwnerDetails = await getUnitDetails(item.id);
      setActiveCard(item);
      if (getSideMenuStatus()) setAdvanceSearchBtn(true);
      dispatch(ActiveItemActions.activeItemRequest(item));
      const activeData = detailsUnitsList.result[selectedIndex];

      const ownerMobileNumber = {
        iconClasses: "mdi mdi-point-of-sale",
        title: "owner-mobile-number",
        value: !selectedLeadOwnerDetails?.phone
          ? "N/A"
          : selectedLeadOwnerDetails?.phone,
      };

      const details = [
        ...activeData.details.slice(0, 1),
        ownerMobileNumber,
        ...activeData.details.slice(1),
      ];

      const updatedActiveData = {
        ...activeData,
        details,
      };

      sideMenuComponentUpdate(
        <CardDetailsComponent
          activeData={updatedActiveData}
          cardDetailsActionClicked={detailedCardSideActionClicked}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          reloadData={reloadData}
          displyOpenFileButton={displayOpenFileButton}
          displyMatchingButton={displayMatchingButton}
          onClickActiveData={() => {
            onOpenDialogHandler("Seller Information");
          }}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      detailedCardSideActionClicked,
      detailsUnitsList.result,
      reloadData,
      advanceSearchBtn,
    ]
  );

  const onSelectClicked = useCallback(
    (row, rowIndex) => {
      setCheckedDetailedCards((items) => {
        const index = items.findIndex((item) => item === rowIndex);
        if (index !== -1) items.splice(index, 1);
        else items.push(rowIndex);
        return [...items];
      });

      setCheckedCards((items) => {
        const index = items.findIndex((item) => item.id === row.id);
        if (index !== -1) items.splice(index, 1);
        else items.push(row);
        return [...items];
      });
    },
    [checkedCards]
  );

  const getCommaSeparatedIds = (IdsArray) => {
    let IdsString = IdsArray.reduce(
      (result, item) => result + item.id + ",",
      ""
    );
    return IdsString.slice(0, IdsString.length - 1);
  };

  const getIsSelected = useCallback(
    (row) =>
      checkedCards &&
      checkedCards.findIndex((item) => item.id === row.id) !== -1,
    [checkedCards]
  );

  const focusedRowChanged = useCallback(
    async (rowIndex) => {
      if (rowIndex !== -1) {
        const activeData = detailsUnitsList.result[rowIndex];

        sideMenuComponentUpdate(
          <CardDetailsComponent
            activeData={detailsUnitsList.result[rowIndex]}
            cardDetailsActionClicked={detailedCardSideActionClicked}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            reloadData={reloadData}
            getUnitsData={getUnitsData}
            displyOpenFileButton={displayOpenFileButton}
            displyMatchingButton={displayMatchingButton}
            onClickActiveData={() => {
              onOpenDialogHandler("Seller Information");
            }}
          />
        );
        sideMenuIsOpenUpdate(true);
      } else {
        sideMenuComponentUpdate(<></>);
        sideMenuIsOpenUpdate(false);
      }
    },
    [detailedCardSideActionClicked, detailsUnitsList.result, reloadData]
  );

  const onFilterValuesChanged = (newValue) => {
    setUnitsTableFilter(newValue);
  };

  useEffect(() => {
    if (
      returnPropsByPermissions(
        PrimaryUnitsPermissions.ViewPrimaryUnits.permissionsId
      ) && activeActionType !== ViewTypesEnum.map.key
    ) {
      const currentPageIndex = localStorage.getItem(
        "unitsSalesCurrentPageIndex"
      );
      const pageIndex = JSON.parse(currentPageIndex);
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={pageIndex || filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={detailsUnitsList.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  useEffect(() => {
    const data = localStorage.getItem("UnitSaleFilter");
    if (data) {
      const saleSearchFilter = JSON.parse(data);
      setSearchData(saleSearchFilter);
      if (saleSearchFilter && saleSearchFilter.length)
        searchChangedClickedWithoutFilter(saleSearchFilter);
    } else getUnitsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFirst1) {
      if (searchData && searchData.length === 0) {
        localStorage.removeItem("UnitSaleFilter");
      } else localStorage.setItem("UnitSaleFilter", JSON.stringify(searchData));
    } else setFirst1(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  useEffect(() => {
    if (!isFirst) setFirst(true);
    else {
      const data = localStorage.getItem("UnitSaleFilter");
      if (data) {
        const dataConvert = JSON.parse(data);
        if (dataConvert && dataConvert.length)
          searchChangedClickedWithoutFilter(dataConvert);
      } else if (searchData && searchData.length === 0) getUnitsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterSearchDto,
    filter,
    unitsPortal,
    unitPublishStatus,
    orderBy,
    unitsTableFilter,
    searchData,
    map,
    selectedPropertyForMap,
  ]);

  useEffect(() => {
    if (isFirst && isDatePickerChanged) {
      const searchDataFilter = searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType:
                TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {});
      getUnitsData(searchDataFilter);
    }
  }, [isDatePickerChanged, searchData]);

  useEffect(() => {
    getAllSystemTemplateByCategoryId();
  }, [getAllSystemTemplateByCategoryId]);

  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);

  useEffect(() => {
    GetSideMenuStatus();
  }, [GetSideMenuStatus]);

  useEffect(() => {
    setDisplayOpenFileButton(
      havePermissionToViewDetails(UnitsVerticalTabsData.sale)
    );
    setDisplayMatchingButton(true);
  }, [isFirst]);

  const onCloseDialogHandler = () => {
    setOwnerDetailsDialog({
      ...ownerDetailsDialog,
      isOpen: false,
      titleText: "",
    });
  };

  const onOpenDialogHandler = (titleText) => {
    setOwnerDetailsDialog({
      ...ownerDetailsDialog,
      isOpen: true,
      titleText,
    });
  };

  const getIsActionDisabled = () => {
    const isProposalUnitsUnchecked =
      activeSelectedAction === "send-Proposal" && checkedCards?.length == 0;
    return isProposalUnitsUnchecked;
  };

  const onPinClick = ({ name }) => () =>{

    setUnitCardsByProperty(true)
    setSelectedPropertyForMap(
      { key: 'property_name', title: 'Property Name *', value: name }
    );
    sideMenuIsOpenUpdate(true)
    
  }
  useEffect(()=>{
    if (unitsCardsByProperty && getSideMenuStatus() && isMapView) {
      sideMenuComponentUpdate(
          <UnitsByProperty
            {...detailsUnitsList}
            isLoading={isLoading}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            pageIndex={filter.pageIndex}
            pageSize={filter.pageSize}
            onPageIndexChange={(index) =>
              setFilter((prevFilter) => ({ ...prevFilter, pageIndex: index }))
            }
            onPageSizeChange={(size) =>
              setFilter((prevFilter) => ({ ...prevFilter, pageSize: size }))
            }
            selectedCards={selectedUnitsCards}
            onSelectCard={(item) => 
              setSelectedUnitsCards((prevSelected) => prevSelected.concat(item))
            }
            onRemoveCard={(id) => 
              setSelectedUnitsCards((prevSelected) => prevSelected.filter(unit => unit.id !== id))
            }
            propertyName={selectedPropertyForMap?.value}
            isLease
          />
      );
    }
  },[unitsCardsByProperty, isLoading, detailsUnitsList, selectedUnitsCards, searchData])

  useEffect(()=>{
    setUnitCardsByProperty(getSideMenuStatus())
  },[getSideMenuStatus()])

  useEffect(()=>{
    if (activeActionType !== ViewTypesEnum.map.key) {
      setMap(null);
      setSelectedPropertyForMap(null);
    }
  },[activeActionType])

  return (
    <>
      <div className="view-wrapper sale-view">
        {!(isOpen && isMapChanged) && <Spinner isActive={isLoading} />}
        <div className="d-flex-column">
          <div className="header-section">
            <div className="filter-section fa-start">
              <div className="section  " style={{ flexDirection: "column" }}>
                <ActionsButtonsComponent
                  permissionsList={Object.values(PrimaryUnitsPermissions)}
                  addPermissionsId={
                    PrimaryUnitsPermissions.AddPrimaryUnits.permissionsId
                  }
                  selectPermissionsId={[
                    PrimaryUnitsPermissions.AddPrimaryUnits.permissionsId,
                    PrimaryUnitsPermissions.SendProposal.permissionsId,
                    PrimaryUnitsPermissions.QuickTransaction.permissionsId
                  ]}
                  onActionsButtonClicked={onActionsButtonClicked}
                  onActionButtonChanged={onActionButtonChanged}
                  enableSendProposal={returnPropsByPermissions(
                    PrimaryUnitsPermissions.SendProposal.permissionsId
                  )}
                  isDisabled={
                    activeTemplateId === null || getIsActionDisabled()
                  }
                  enableMakeTransaction={returnPropsByPermissions(PrimaryUnitsPermissions.QuickTransaction.permissionsId)}
                  wrapperClasses="wrap-flex"
                  separateMakeTransactionButton={returnPropsByPermissions(PrimaryUnitsPermissions.QuickTransaction.permissionsId)}
                   withText='send-Proposal'
                   sendProposalAction
                />
                <div className="mt-2">  
                {activeSelectedAction === "send-Proposal" && (
                  <SelectComponet
                    data={templatesProposal}
                    keyLoopBy="systemTemplateId"
                    valueInput="systemTemplateId"
                    textInput="systemTemplateName"
                    value={activeTemplateId}
                    emptyItem={{
                      value: null,
                      text: "select-template",
                      isDisabled: false,
                    }}
                    keyValue="actionsbuttons"
                    idRef="contactsActionsRef"
                    onSelectChanged={(value) => {
                      setActiveTemplateId(value);
                    }}
                    themeClass="theme-action-buttons"
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                    wrapperClasses="bg-secondary c-white mx-2"
                    parentTranslationPath={parentTranslationPath}
                  />
                )}
                </div>
              </div>

              <PermissionsComponent
                permissionsList={Object.values(PrimaryUnitsPermissions)}
                permissionsId={
                  PrimaryUnitsPermissions.ViewPrimaryUnits.permissionsId
                }
              >
                <div className="section autocomplete-section">
                  <div className="d-flex-column px-2 w-100 p-relative">
                    <PrimaryUnitsFilterComponent
                      getUnitsData={getUnitsData}
                      isClearFiltersClicked={isClearFiltersClicked}
                    />
                  </div>
                </div>
                <div className="section autocomplete-section">
                <div className="view-search-wrapper">
                    <ViewTypes
                      onTypeChanged={onTypeChanged}
                      initialActiveType={activeActionType}
                      className="mb-3"
                      withMap
                    />
                    <span className="advance-search">
                      <ButtonBase
                        onClick={onAdvanceSearchClick}
                        id="AdvanceSearch_bbt"
                        disabled={isAllFormFieldsLoading}
                        className={`btns theme-solid ${
                          isAdvanceSearchActive &&
                          !isAllFormFieldsLoading &&
                          "is-active-filter"
                        }`}
                      >
                        <span
                          className={`${
                            advanceSearchBtn
                              ? "mdi mdi-arrow-expand-left"
                              : "mdi mdi-arrow-expand-right"
                          } mr-1`}
                        />
                        {t(`${translationPath}advance-search`)}
                      </ButtonBase>
                    </span>
                    <ButtonBase
                      onClick={onClearedAllFiltersClick}
                      id="onClearedAllFiltersref"
                      disabled={isAllFormFieldsLoading}
                      className="btns theme-solid bg-danger clear-all-btn"
                    >
                      <span className="mdi mdi-filter-remove m-1" />
                      {t(`${translationPath}clear-filters`)}
                    </ButtonBase>
                  </div>
</div>
              </PermissionsComponent>
            </div>
            {selectedUnitsCards.length !== 0 && (
               <div className=" p-3">
                <div className=" d-flex">
                  <Button variant='contained' className="btns theme-solid" color='primary' onClick={()=> setIsSelectedUnitDialogOpen(true)}>
                      <strong>({selectedUnitsCards.length}) selected units</strong>
                  </Button>
                  <SelectComponet
                    data={templatesProposal}
                    keyLoopBy="systemTemplateId"
                    valueInput="systemTemplateId"
                    textInput="systemTemplateName"
                    value={activeTemplateId}
                    emptyItem={{
                      value: null,
                      text: "select-template",
                      isDisabled: false,
                    }}
                    keyValue="actionsbuttons"
                    idRef="contactsActionsRef"
                    onSelectChanged={(value) => {
                      setActiveTemplateId(value);
                      setIsProposalDialogOpen(true)
                    }}
                    themeClass="theme-action-buttons"
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                    wrapperClasses="bg-secondary c-white mx-2"
                    parentTranslationPath={parentTranslationPath}
                  />
                </div>
              </div>
                )}
          </div>
          {[ViewTypesEnum.cards.key, ViewTypesEnum.cardsExpanded.key].includes(activeActionType) && (
            <>
              <div className="body-section">
                <PermissionsComponent
                  permissionsList={Object.values(PrimaryUnitsPermissions)}
                  permissionsId={
                    PrimaryUnitsPermissions.ViewPrimaryUnits.permissionsId
                  }
                >
                  <UnitsCardsComponent
                    data={detailsUnitsList}
                    isExpanded={isExpanded}
                    onCardClicked={onCardClick}
                    onFooterActionsClicked={detailedCardSideActionClicked}
                    checkedDetailedCards={checkedDetailedCards}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onCardCheckboxClick={cardCheckboxClicked}
                    activeCard={activeCard}
                    withCheckbox={activeSelectedAction === "send-Proposal"}
                    displyOpenFileButton={displayOpenFileButton}
                    displyMatchingButton={displayMatchingButton}
                  />
                </PermissionsComponent>
              </div>
            </>
          )}
          {activeActionType === ViewTypesEnum.tableView.key && (
            <PermissionsComponent
              permissionsList={Object.values(PrimaryUnitsPermissions)}
              permissionsId={
                PrimaryUnitsPermissions.ViewPrimaryUnits.permissionsId
              }
            >
              <UnitsSalesTableComponent
                detailsUnitsList={detailsUnitsList}
                tableActionClicked={tableActionClicked}
                onPageIndexChanged={onPageIndexChanged}
                onPageSizeChanged={onPageSizeChanged}
                unitsTableFilter={unitsTableFilter}
                onFilterValuesChanged={onFilterValuesChanged}
                filter={filter}
                parentTranslationPath={parentTranslationPath}
                focusedRowChanged={focusedRowChanged}
                onSelectClicked={onSelectClicked}
                setCheckedCards={setCheckedCards}
                checkedCardsIds={checkedCards}
                getIsSelected={getIsSelected}
                activeSelectedAction={activeSelectedAction}
                setOrderBy={setOrderBy}
                isClearFiltersClicked={isClearFiltersClicked}
                setIsClearFiltersClicked={setIsClearFiltersClicked}
                displyOpenFileButton={displayOpenFileButton}
                displyMatchingButton={displayMatchingButton}
                withCheckbox
                localStorageKey="UnitsSalesFilter"
              />
            </PermissionsComponent>
          )}
           {activeActionType === ViewTypesEnum.map.key && (
            <UnitsMapView
              key={mapKey}
              setMap={map => {
                setIsMapChanged(true)
                setMap(map)}
              }
              totalCount={detailsPropertyByUnitsList.totalCount}
              totalUnitsCount={detailsPropertyByUnitsList.result?.[0]?.totalCountUnits}
              isLoading={isLoading}
              locations={detailsPropertyByUnitsList.result
                .map((property, index) => {
                  return {
                    latitude: property?.latitude,
                    longitude:  property?.longitude,
                    name: property?.propertyName,
                    id: property?.propertyId,
                    propertyTypeId: property?.propertyTypeId,
                    noOfUnits: property?.noOfUnits,
                    createdOn: property?.createdOn,
                    propertyStatus: property?.property_plan,
                    index,
                  };
                })}
                onPinClick={onPinClick}
                stopChangeCapturing={stopChangeCapturing}
          />)}
        </div>
        {addActivity && (
          <ActivitiesManagementDialog
            open={addActivity}
            onSave={() => {
              setAddActivity(false);
            }}
            close={() => {
              setAddActivity(false);
            }}
            actionItemId={activeItem?.id}
            translationPath={""}
            parentTranslationPath={"ActivitiesView"}
          />
        )}
      </div>

      {ownerDetailsDialog.isOpen && (
        <DialogComponent
          isOpen={ownerDetailsDialog.isOpen}
          onCloseClicked={onCloseDialogHandler}
          titleText={ownerDetailsDialog.titleText}
          maxWidth="lg"
          dialogContent={
            <OwnerDetailsDialog
              data={leadOwnerDetails}
              translationPath={parentTranslationPath}
            />
          }
        />
      )}

      {isProposalDialogOpen && (
        <UnitTemplateDialogPreviewDialog
          activeItem={activeTemplateId}
          isOpen={isProposalDialogOpen}
          isOpenChanged={() => {
            setIsProposalDialogOpen(false);
          }}
          unitIDsList={getCommaSeparatedIds(checkedCards)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isSelectedUnitDialogOpen && <DialogComponent
        isOpen
        maxWidth={'lg'}
        titleText={'selected-units'}
        onCloseClicked={()=> setIsSelectedUnitDialogOpen(false)}
        dialogContent={
          <div className=" d-flex fj-center flex-wrap gap-1">
           {Children.toArray(selectedUnitsCards.map((item) => (
              <UnitCard
              item={item}
              onRemoveCard={(id) =>
                setSelectedUnitsCards((prev) =>
                  prev.filter((unit) => unit.id !== id)
                )
              }
              selectedCards={selectedUnitsCards}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />        
            )))}
          </div>
        }
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />}  
    </>
  );
};
