import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../Hooks";

export default makeStyles((theme) => ({
  modalContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 11,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  text: {
    fontSize: "18px",
    fontWeight: 600,
  },
  modalPaper: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    maxWidth: "400px",
    width: "400px",
    padding: "24px",
    borderRadius: "12px",
    background: theme.palette.background.paper,
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  },
  actions: {
    background: theme.palette.background.paper,
    paddingTop: "16px",
    alignItems: "center",
    gap: "12px",
    display: "flex",
    justifyContent: "flex-end",
  },
  inputField: {
    boxShadow: "none",
  },
}));
