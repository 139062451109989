import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    container: {
      left: "50%",
      zIndex: 100,
      position: "absolute",
      top: "75px",
      transform: "translate(-50%, 0)",
      display: "flex",
      maxWidth: "1216px",
      padding: "16px",
      alignItems: "flex-start",
      gap: "16px",
      borderRadius: "12px",
      border: `1px solid ${theme.palette.border.primary}`,
      background: theme.palette.background.primary_alt,
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    },
    text: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      fontSize: "14px",
      lineHeight: "20px",
      "& span": {
        color: theme.palette.text.secondary,
        fontWeight: 600,
      },
      "& p": {
        color: theme.palette.text.tertiary,
        fontWeight: 400,
        marginBottom: "12px",
      },
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    buttonsContainer: {
      display: "flex",
      gap: "8px",
    },
    closeButton: {
      position: "absolute",
      top: "8px",
      right: "8px",
      padding: "8px",
      borderRadius: "8px",
      "&:hover": {
        background: theme.palette.background.primary_hover,
      },
    },
    closeIcon: {
      alignSelf: "center",
    },
  };
});
