import React, { createContext, useState, useContext, useCallback } from "react";

// Create the context
const VerticalNavContext = createContext();

// Provider Component
export const VerticalNavProvider = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [hoverExpanded, setHoverExpanded] = useState(false);
  const [isMobileSideNavOpen, setIsMobileSideNavOpen] = useState(false);
  const [mainContainerPadding, setMainContainerPadding] = useState("32px");

  const onTogglePinned = () => {
    setIsExpanded(!isExpanded);
    setHoverExpanded(false); // Ensure hover is disabled when toggling pinned
  };

  const onToggleMobileSideNav = () => {
    setIsMobileSideNavOpen(!isMobileSideNavOpen);
  };

  const memoizedSetMainContainerPadding = useCallback((padding) => {
    setMainContainerPadding(padding);
  }, []);

  const [tablePopoverContent, setTablePopoverContent] = useState(null);

  const [alertBoxContent, setAlertBoxContent] = useState(null);

  // Context value that will be supplied to any consuming component
  const contextValue = {
    tablePopoverContent,
    setTablePopoverContent,
    isExpanded,
    setIsExpanded,
    hoverExpanded,
    setHoverExpanded,
    onTogglePinned,
    sideNavWidth: {
      expanded: "312px",
      collapsed: "80px",
    },
    navNotCollapsed: isExpanded || hoverExpanded,
    isMobileSideNavOpen,
    onToggleMobileSideNav,
    mainContainerPadding,
    setMainContainerPadding: memoizedSetMainContainerPadding,
    alertBoxContent,
    setAlertBoxContent,
  };

  return (
    <VerticalNavContext.Provider value={contextValue}>
      {children}
    </VerticalNavContext.Provider>
  );
};

// Custom hook to use the context
export const useVerticalNav = () => useContext(VerticalNavContext);
