// import React, { useRef } from "react";
// import { useTranslation } from "react-i18next";
// import { Inputs } from "../../../../Components";

// export const SizeFilter = ({
//   resaleChangeFilters,
//   parentTranslationPath,
//   translationPath,
//   selected ,
//   setSelected ,
// }) => {
//   const { t } = useTranslation(parentTranslationPath);
//   const searchTimer = useRef(null);

//   return (
//     <div className="w-50 px-2">
//       <fieldset className="styled-fieldset d-flex-column-v-center">
//         <legend className="styled-legend">{t(`${translationPath}size`)}</legend>
//         <div className="w-100 m-1 px-2">
//           <Inputs
//             idRef="sizeFromRef"
//             labelValue={t(`${translationPath}from`)}
//             type={"number"}
//             value={(selected?.size?.from) || ''}
//             withNumberFormat
//             onKeyUp={(e) => {
//               const { value } = e.target;
//               let size = null ;
//               if (searchTimer.current) clearTimeout(searchTimer.current);
//               searchTimer.current = setTimeout(() => {
//                 const sizeFromKey = "builtup_area_sqft_from";
//                 const sizeFromValue =
//                   value || value == 0 ? value.replace(/,/g, "") : null;
//                 const searchType = 3;

//                 resaleChangeFilters(
//                   sizeFromKey,
//                   sizeFromValue,
//                   false,
//                   searchType
//                 );
//                 size = { from : sizeFromValue , to :(selected?.size?.to)} ;
//                 setSelected({id :'size'  , value : size })
//               }, 600);

//             }}
//             min={0}
//           />
//         </div>
//         <div className="w-100 m-1 px-2">
//           <Inputs
//             idRef="sizeToRef"
//             labelValue={t(`${translationPath}to`)}
//             type={"number"}
//             value={(selected?.size?.to) || ''}
//             withNumberFormat
//             onKeyUp={(e) => {
//               const { value } = e.target;
//               let size =  null ;
//               if (searchTimer.current) clearTimeout(searchTimer.current);
//               searchTimer.current = setTimeout(() => {
//                 const sizeToKey = "builtup_area_sqft_to";
//                 const sizeToValue =
//                   value || value == 0 ? value.replace(/,/g, "") : null;
//                 const searchType = 4;
//                 resaleChangeFilters(sizeToKey, sizeToValue, false, searchType);
//                 size = { from : (selected?.size?.from) , to :sizeToValue} ;
//                 setSelected({id :'size'  , value : size });
//               }, 600);
//             }}
//             min={0}
//           />
//         </div>
//       </fieldset>
//     </div>
//   );
// };

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Inputs } from '../../../../Components';
import { InputAdornment, Slider } from '@material-ui/core';
import './SizeFilter.scss';

export const SizeFilter = ({
  parentTranslationPath,
  translationPath,
  selected,
  setSelected,
  PriceAndSizeChangeFilters,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const handleSliderChange = (event, newValue) => {
    const [minValue, maxValue] = newValue;
    setSelected({ id: 'size', value: { from: minValue, to: maxValue } });
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }
    searchTimer.current = setTimeout(() => {
      PriceAndSizeChangeFilters('builtup_area_sqft', {
        from: minValue,
        to: maxValue,
      });
    }, 1000);
  };

  const handleInputChange = (value, type) => {
    if (type === 'from') {
      setSelected({
        id: 'size',
        value: { from: value },
      });
      if (searchTimer.current) {
        clearTimeout(searchTimer.current);
      }
      searchTimer.current = setTimeout(() => {
        PriceAndSizeChangeFilters('builtup_area_sqft', {
          from: value,
          to: selected?.size?.to,
        });
      }, 1000);
    } else {
      setSelected({
        id: 'size',
        value: { from: selected?.size?.from, to: value },
      });
      if (searchTimer.current) {
        clearTimeout(searchTimer.current);
      }
      searchTimer.current = setTimeout(() => {
        PriceAndSizeChangeFilters('builtup_area_sqft', {
          from: selected?.size?.from,
          to: value,
        });
      }, 1000);
    }
  };

  return (
    <div className='size-filter-container'>
      <fieldset className='styled-fieldset'>
        <legend className='styled-legend'>{t(`${translationPath}size`)}</legend>
        <div className='slider-container'>
          <Slider
            value={[
              Number(selected?.size?.from) || 500,
              Number(selected?.size?.to) || 500,
            ]}
            onChange={handleSliderChange}
            min={0}
            max={5000}
            valueLabelDisplay='auto'
            className='size-slider'
          />
        </div>
        <div className='inputs-container'>
          <Inputs
            idRef='sizeFromRef'
            type='number'
            value={selected?.size?.from || ''}
            onInputChanged={(event) => {
              const { value } = event.target;
              handleInputChange(value, 'from');
            }}
            endAdornment={<InputAdornment position='start'>Ft</InputAdornment>}
            min={0}
            className='size-input'
          />
          <span className='range-separator'>-</span>
          <Inputs
            idRef='sizeToRef'
            type='number'
            value={selected?.size?.to || ''}
            onInputChanged={(event) => {
              const { value } = event.target;
              handleInputChange(value, 'to');
            }}
            endAdornment={<InputAdornment position='start'>Ft</InputAdornment>}
            min={0}
            className='size-input'
          />
        </div>
      </fieldset>
    </div>
  );
};
