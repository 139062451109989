import { DevelopersView  } from '../../Views/Home/DevelopersView' ; 
import {  DevelopersManagementView } from '../../Views/Home/DevelopersView/DevelopersUtilities/DevelopersManagementView' ; 
export const DevelopersRoutes = [
  {

    path: "/view",
    name: "Developers:developers",
    component: DevelopersView ,
    layout: "/home/developers",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "Developers:developers",
        isDisabled: true,
        route: "/home/developers/view",
        groupName: 'crm',

      },
    ],
  },
  {
    path: '/add',
    name: 'Developers:developer-management-view',
    component: DevelopersManagementView,
    layout: '/home/developers',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "Developers:developers",
        isDisabled: false,
        route: '/home/developers/view',
        groupName: 'crm',
      },
      {
        name: 'Developers:developer-management-view',
        isDisabled: true,
        route: '/home/developers/add',
      },
    ],
  },
  {
    path: '/developer-profile-edit',
    name: 'developers:developer-profile-edit',
    component: DevelopersView,
    layout: '/home/developers',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'developers:developers',
        isDisabled: false,
        route: '/home/developers/view',
        groupName: 'crm',
      },
      {
        name: 'developers:developer-profile-edit',
        isDisabled: true,
        route: '/home/developers/developer-profile-edit',
      },
    ],
  },
];
