import React, { useCallback, useState } from 'react';
import { Button, ButtonBase, DialogActions } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { showError, showSuccess, showWarn } from '../../../../../../../Helper';
import { SendUnitProposalToLeadAPI } from '../../../../../../../Services';
import { useTranslation } from 'react-i18next';
import { config } from '../../../../../../../config';
import { UnitsCardsComponent } from '../../../../../UnitsLeaseView';
import { Spinner } from '../../../../../../../Components';
import { UnitsCardsComponent as SaleUnitsCardsComponent } from '../../../../../UnitsSalesView';

function MatchedUnitView({
  setView,
  data,
  views,
  leadEmail,
  leadId,
  isSale,
  onClose,
}) {
  const { t } = useTranslation('LeadsProfileManagementView');
  const [checkedDetailedCardsIds, setCheckedDetailedCardsIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const Unit = isSale ? SaleUnitsCardsComponent : UnitsCardsComponent;
  const sendSelectedMatchedHandler = useCallback(async () => {
    setIsLoading(true);
    if (leadEmail === null || leadEmail === 'N/A' || leadEmail === '') {
      showWarn(t(`${t('Shared:email-address-missing')}`));
      setIsLoading(false);
      return;
    }
    if (leadId && checkedDetailedCardsIds && checkedDetailedCardsIds.length) {
      const result = await SendUnitProposalToLeadAPI(
        [leadId],
        checkedDetailedCardsIds,
        null,
        isSale
          ? config.SalesUnitProposalTemplateId
          : config.LeasingUnitProposalTemplateId,
        config.SendKey,
        config.server_name
      );
      if (!(result && result.status && result.status !== 200)) {
        showSuccess(t`selected-matches-sent-successfully`);
        onClose();
      } else showError(t`selected-matches-sending-failed`);
    }
    setIsLoading(false);
  });
  return (
    <div>
      <Spinner isAbsolute iActive={isLoading} />
      <ButtonBase onClick={() => setView(views.search)}>
        <ArrowBack />
      </ButtonBase>
      {data.length > 0 ? (
        <Unit
          data={{ result: data, totalCount: data.length }}
          parentTranslationPath='UnitsView'
          translationPath=''
          withCheckbox
          checkedDetailedCards={checkedDetailedCardsIds}
          onCardCheckboxClick={(_index, item) =>
            setCheckedDetailedCardsIds((prevIds) => {
              if (prevIds.includes(item.id)) {
                return prevIds.filter((id) => id !== item.id);
              } else {
                return prevIds.concat(item.id);
              }
            })
          }
          hideStatus
        />
      ) : (
        <h4 className='text-center'>
          {t('utilities.cardDetailsComponent.no-units-found', {
            ns: 'LeadsView',
          })}
        </h4>
      )}
      <DialogActions>
        <Button
          type='button'
          className='btns'
          onClick={() => setView(views.search)}
        >
          Back
        </Button>
        <Button
          type='button'
          className='btns theme-solid'
          onClick={sendSelectedMatchedHandler}
          disabled={isLoading || checkedDetailedCardsIds.length === 0}
        >
          {t('send-selected-matches')}
        </Button>
      </DialogActions>
    </div>
  );
}

export default MatchedUnitView;
